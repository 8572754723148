import React, { useEffect } from "react";
import "./user-chatList.css";
import BaseUrl from "../../Server/BaseUrl";

const UserChatList = ({ chats, onChatSelect, selectedChatId }) => {
  useEffect(() => {
    console.log("Chats Array:", chats);
  }, [chats]);

  return (
    <div className="custom-chat-container">
      {chats.map((chat) => {
        const sanitizedProfileUrl = chat.profile_url

        return (
          <div
            key={chat.user_id}
            className={`custom-chat-item ${
              selectedChatId === chat.user_id ? "active" : ""
            }`}
            onClick={() => onChatSelect(chat.user_id)}
          >
            <img
              src={`${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`}
              alt=""
              className="custom-avatar"
            />
            <div className="flex-1">
              <h4 className="custom-chat-name">
                {chat.first_name} {chat.last_name}
              </h4>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserChatList;
