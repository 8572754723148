import React, { useState, useEffect } from "react";
import MessageInput from "./MessageInput";
import "./MainMessage.css";
import axios from "axios";

import { useLocation } from "react-router-dom";
import HomeHeader from "../HomeHeader";
import BaseUrl from "../../Server/BaseUrl";
import UserChatList from "./UserChatList";
import UserChatHeader from "./UserChatHeader";
import UserMessagesPanel from "./UserMessagesPanel";

const UserMainChats = () => {
  const [currentChat, setCurrentChat] = useState(null); // The currently selected chat
  const [chats, setChats] = useState([]); // List of chats
  const [messages, setMessages] = useState([]); // Messages of the selected chat
  const [receiver_user_id, setReceiverUserId] = useState(null);
  const [userData, setUserData] = useState(null);

  const location = useLocation();
  const receivedUserData = location?.state?.userData;
  const sender_user_id = receivedUserData?.user_id;

  // Polling interval
  const POLLING_INTERVAL = 3000; // 3 seconds

  useEffect(() => {
    if (receivedUserData) {
      setUserData(receivedUserData);
      fetchChats();
    }
  }, [receivedUserData]);

  // Fetch chat list for the sender
  const fetchChats = () => {
    axios
      .get(`${BaseUrl.BaseUrl}/chat/list/${sender_user_id}`)
      .then((response) => {
        if (response.data.success) {
          setChats(response.data.chats);
        }
      })
      .catch((error) => console.error("Error fetching chats:", error));
  };

   // Fetch messages when sender and receiver are defined
   useEffect(() => {
    let polling;
    if (sender_user_id && receiver_user_id) {
      fetchMessages();

      // Start polling
      polling = setInterval(fetchMessages, POLLING_INTERVAL);
    }

    return () => {
      // Cleanup polling
      if (polling) clearInterval(polling);
    };
  }, [sender_user_id, receiver_user_id]);

  
  const fetchMessages = () => {
    axios
      .get(
        `${BaseUrl.BaseUrl}/chat/fetch/${sender_user_id}/${receiver_user_id}`
      )
      .then((response) => {
        setMessages(response.data.messages);
      })
      .catch((error) => console.error("Error fetching messages:", error));
  };

  // Handle chat selection from the sidebar
  const handleChatSelect = (chatId) => {
    const selectedChat = chats.find((chat) => chat.user_id === chatId);
    setCurrentChat(selectedChat);
    setReceiverUserId(chatId);
  };

  // Handle sending a message
  const handleSendMessage = (text) => {
    if (!receiver_user_id) return;

    const newMessage = {
      sender_user_id,
      receiver_user_id,
      message: text,
      file_url: "",
    };

    axios
      .post(`${BaseUrl.BaseUrl}/chat/send`, newMessage)
      .then((response) => {
        const savedMessage = {
          ...response.data.newMessage,
          senderName: "You",
        };
        setMessages((prevMessages) => [...prevMessages, savedMessage]);
      })
      .catch((error) => console.error("Error sending message:", error));
  };

  // Handle file sending
  const handleFileSend = (file) => {
    if (!receiver_user_id) return;

    const newMessage = {
      sender_user_id,
      receiver_user_id,
      message: `File: ${file.name}`,
      file_url: "", // Add file handling logic here if needed
    };

    axios
      .post(`${BaseUrl.BaseUrl}/chat/send`, newMessage)
      .then((response) => {
        setMessages([...messages, response.data.message]);
      })
      .catch((error) => console.error("Error sending file message:", error));
  };

  // Handle image sending
  const handleImageSend = (image) => {
    if (!receiver_user_id) return;

    const newMessage = {
      sender_user_id,
      receiver_user_id,
      message: "Image sent",
      file_url: URL.createObjectURL(image),
    };

    axios
      .post(`${BaseUrl.BaseUrl}/chat/send`, newMessage)
      .then((response) => {
        setMessages([...messages, response.data.message]);
      })
      .catch((error) => console.error("Error sending image message:", error));
  };

  return (
    <div>
      <HomeHeader />
      <div className="main-message-container">
        {/* Chat List (Sidebar) */}
        <div className="chat-list-sidebar">
          <UserChatList
            chats={chats}
            onChatSelect={handleChatSelect}
            selectedChatId={currentChat?.user_id}
          />
        </div>

        {/* Messages Panel */}
        <div className="messages-panel">
          {currentChat ? (
            <>
              <UserChatHeader user={currentChat} />
              <div className="messages-panel-content">
                <UserMessagesPanel
                  messages={messages}
                  currentUser={{ user_id: sender_user_id, name: "You" }}
                />
              </div>
              <MessageInput
                onSend={handleSendMessage}
                onFileSend={handleFileSend}
                onImageSend={handleImageSend}
              />
            </>
          ) : (
            // Show blank screen when no chat is selected
            <div className="no-chat-selected">
              {/* <p>Select a chat to start messaging</p> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMainChats;
