import React from "react";
import PropTypes from "prop-types";

const SkeletonWrapper = ({ items = [], containerClass = "" }) => {
  return (
    <div className={containerClass}>
      {items.map((item, index) => (
        <div key={index} className={`skeleton-item ${item.className}`} />
      ))}
    </div>
  );
};

SkeletonWrapper.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
      borderRadius: PropTypes.string,
      backgroundColor: PropTypes.string,
      marginRight: PropTypes.string,
      marginBottom: PropTypes.string,
      className: PropTypes.string,
    })
  ),
  containerClass: PropTypes.string,
};

export default SkeletonWrapper;
