import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./List.css";
import styles from "../Listing.module.css";
import BaseUrl from "../../Server/BaseUrl";
 
const ListingList = ({ props_data }) => {
  const navigate = useNavigate();
  const { userid } = useParams();
  const [popupData, setPopupData] = useState(null); // Store the info for the popup
  const [notifications, setNotifications] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Control the visibility of the popup
 
  useEffect(() => {
    const fetchNotifications = async () => {
      const user_id = localStorage.getItem("user_id");
      try {
        const response = await fetch(
          `${BaseUrl.BaseUrl}/api/owner/notifications?user_id=${user_id}`
        );
        const data = await response.json();
 
        if (response.ok) {
          setNotifications(data?.notifications || [])
          // Save notifications to state
          console.log("Notification data:", data);
        } else {
          throw new Error("Failed to fetch notifications");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error.message);
      }
    };
 
    fetchNotifications();
  }, []);
 
  const handleEditClick = (property) => {
    navigate(`/EditForm/${property?.property_id}`, {
      state: { propertyData: property },
    });
  };
 
  const handlePopupClick = (property) => {
    // Find the notification with the same property_id as the property
    const relatedNotification = notifications.find(
      (notification) => notification.property_id === property.property_id
    );
 
    if (relatedNotification) {
      setPopupData({
        info:
          relatedNotification.info || "No additional information available.",
        reason: relatedNotification.reason || "No reason provided.",
      });
    } else {
      setPopupData({
        info: "No additional information available.",
        reason: "No reason provided.",
      });
    }
 
    setIsPopupVisible(true);
  };
 
  const closePopup = () => {
    setIsPopupVisible(false); // Close the popup
    setPopupData(null); // Reset the data
  };
 
  const handlePendingClick = () => {
    navigate(`/PandingApproval/${userid}`);
  };
 
  console.log("edit form lists =============>", props_data);
 
  return (
    <div className="containermylist">
      <div className="headerlist">
        <label className="title">Listings</label>
        <Link to="/AddProperty">
          <button className="add-property-button">
            Add New Property
          </button>
        </Link>
      </div>
      <div className="grid-containers">
        {props_data?.map((property, index) => (
          <div
            key={index}
            className="listing-card"
            style={{ position: "relative" }}
          >
            {property?.status === "cancel" && (
              <div className="rejected-overlay">
                <label className="rejected-label">Declined</label>
                <label
                  className="more-info"
                  onClick={() => handlePopupClick(property)}
                >
                  Click to know more
                </label>
              </div>
            )}
 
            <div
              className={`image-container ${property?.status !== "accept" ? "dimmed" : ""
                }`}
            >
              <img
                className="card-image"
                src={
                  property.property_images &&
                  property.property_images[0]?.filename &&
                  `${BaseUrl.BaseUrl}/Images/${property.property_images[0]?.filename}`
                }
                alt="Background"
              />
              {property.status === "pending" ? (
                <div id="pending-overlay">
                  <label id="pending-label" onClick={handlePendingClick}>Pending Approval</label>
                </div>
              ) : (
                <button
                  className="edit-button"
                  onClick={() => handleEditClick(property)}
                >
                  Edit
                </button>
              )}
            </div>
            <div
              className={`image-container ${property?.status === "cancel" ? "dimmed" : ""
                }`}
            >
              <div className="card-row">
                <label className="card-heading">
                  {property?.property_name}
                </label>
                <label className="card-rating">5.0</label>
              </div>
              <div className="card-row">
                <label className="card-description">
                  {property.city} {property.country} -{property?.property_type}{" "}
                  View
                </label>
                <label className="card-location">{property.select_view}</label>
              </div>
              <div className="card-price">
                <div className="price">
                  <label className="price-label">From</label>
                  <label className="price-value">
                    ${property?.price_per_night}
                  </label>
                  <label className="price-unit">/Night</label>
                </div>
                <label className="distance">2000 km Away</label>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Popup */}
      {isPopupVisible && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContainer}>
            <div className={styles.popupContent}>
              <h3>More Information</h3>
              <p style={{ fontWeight: "bolder", textAlign: "center" }}>
                {popupData.reason}
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>Description:</strong> {popupData.info}
              </p>
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default ListingList;
 
 
 