import React, { useState, useEffect } from "react";
import "./MessageInput.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faSmile,
  faPaperclip,
  faImage,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import EmojiPicker from "emoji-picker-react";

const MessageInput = ({ onSend, onFileSend, onImageSend }) => {
  const [text, setText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showFileOptions, setShowFileOptions] = useState(false); // state for showing file options

  useEffect(() => {
    const inputField = document.querySelector(".message-input-field");
    inputField?.focus();
  }, []);

  const handleSend = () => {
    if (text.trim()) {
      onSend(text);
      setText("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const onEmojiClick = (emojiData) => {
    setText((prev) => prev + emojiData.emoji);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileSend(file);
    }
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    if (image) {
      onImageSend(image); // Ensure this is passed properly
    }
  };

  const toggleFileOptions = () => {
    setShowFileOptions((prev) => !prev); // toggle file options visibility
  };

  return (
    <div className="message-input-container1">
      {/* Emoji Button */}
      <button
        className="emoji-button"
        onClick={() => setShowEmojiPicker((prev) => !prev)}
      >
        <FontAwesomeIcon icon={faSmile} />
      </button>

      {showEmojiPicker && (
        <div className="emoji-picker-container">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}

      {/* File Upload Button */}
      <button onClick={toggleFileOptions} className="file-options-button">
        <FontAwesomeIcon icon={faPaperclip} />
      </button>

      {showFileOptions && (
        <div className="file-options">
          {/* File Upload Option */}
          <label className="file-upload-button">
            <FontAwesomeIcon icon={faFile} />
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>

          {/* Image Upload Option */}
          <label className="image-upload-button">
            <FontAwesomeIcon icon={faImage} />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </label>
        </div>
      )}

      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyPress={handleKeyPress} // Trigger handleSend on "Enter"
        placeholder="Type a message"
        className="message-input-field"
      />

      <button onClick={handleSend} className="button-common send-button">
        <FontAwesomeIcon icon={faPaperPlane} />
      </button>
    </div>
  );
};

export default MessageInput;
