import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "./List.css";
import BaseUrl from "../../Server/BaseUrl";
import Loader from "../../../assets/loader.gif";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const CencelBookingUserList = () => {
  const [cancellationReason, setCancellationReason] = useState("");
  const { userid } = useParams();
  const [state, setState] = useState({
    activeBt: "",
    apiData: [], // Initialize as an empty array
    loading: true,
    error: null,
    selectedProfileData: null, // Add this to store profile data
    isProfileLoading: false, // Add to handle profile loading state
    isProfilePopupOpen: false,
  });
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items to display per page

  useEffect(() => {
    fetchData();
  }, [userid, state.activeBt]);

  const fetchData = async () => {
    const API_URL = `${BaseUrl.BaseUrl}/active_booking_user/${userid}`;

    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const filteredData =
        data?.message?.active_bookings?.filter(
          (booking) =>
            booking.status === "cancel by host" ||
            booking.status === "cancel by user"
        ) || [];

      setState((prevState) => ({
        ...prevState,
        apiData: filteredData, // Ensure apiData is an array
        loading: false,
      }));
      //   console.log("data1111", data);
      console.log("data1111", data.message.active_bookings);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.message,
        loading: false,
      }));
      alert(error.message);
    }
  };

  if (state.loading) {
      return (
        <div className="booking-history">
          <SkeletonWrapper
          containerClass="Skeleton-host-booking-list"
          items={[
            { className: "skeleton-header" },
            { className: "skeleton-heading" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-page" },
          ]}
        />
        </div>
      );
    }

  // Function to fetch profile data when "View Profile" is clicked
  const handleViewProfile = (property_id, booking_id) => {
    const selectedItem = currentItems.find(
      (item) =>
        item.property_id === property_id && item.booking_id === booking_id
    );

    if (selectedItem) {
      setState((prevState) => ({
        ...prevState,
        selectedProfileData: selectedItem,
        isProfilePopupOpen: true,
      }));
    }
  };

  const handleCloseProfilePopup = () => {
    setState((prevState) => ({
      ...prevState,
      isProfilePopupOpen: false, // Close the profile popup
    }));
  };

  console.log(
    "user view profile11111======>",
    state?.selectedProfileData?.property_details
  );

  // console.log("user view profile======>", state.selectedProfileData);


  if (state.error) {
    return (
      <div style={{ color: "red", textAlign: "center", paddingTop: 20 }}>
        Error: {state.error}
      </div>
    );
  }

  //   const sanitizedProfileUrl = state?.selectedProfileData?.owner_image?.replace(/^http:\/\/localhost:4000/, '')
  const sanitizedProfileUrl = state?.selectedProfileData?.owner_image

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.apiData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(state.apiData.length / itemsPerPage);
  // console.log("user view profile======>", currentItems);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const parseExtraServices = (services) => {
    try {
      return (
        JSON.parse(services)
          ?.map((service) => service.item)
          .join(", ") || ""
      );
    } catch {
      return "";
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
        Booking Cancellation by User
      </h3>
      <div className="topMain" style={{ width: "100%" }}>
        {currentItems.length === 0 ? (
          <p style={{ textAlign: "center" }}>No bookings available</p>
        ) : (
          <div className="table-Page" style={{ overflowX: "auto" }}>
            <table
              className="table-full"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    SERVICE
                  </th>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    Entry Date & Exit Date
                  </th>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    Booking Date and time
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Total
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "left" }}>
                      {item.nights} Night
                      {item.extra_services && (
                        <> + {parseExtraServices(item.extra_services)}</>
                      )}
                      <br />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          color: "#64748B",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "0px",
                          }}
                        >
                          {" "}
                          Service by:{" "}
                          {item.owner_name === "User"
                            ? item.user_name
                            : item.owner_name}
                          .
                        </p>

                        <button
                          style={{
                            background: "none",
                            color: "#F15A29",
                            fontWeight: "bold",
                          }}
                          onClick={() =>
                            handleViewProfile(item.property_id, item.booking_id)
                          }
                        >
                          View Profile
                        </button>
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.entry_date} to {item.exit_date}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.booking_date}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      ${item.total_amount}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          border: "none",
                          borderRadius: 5,
                          whiteSpace:"nowrap",
                        }}
                      >
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination Controls */}
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="previbutton"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent2.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: "none",
                cursor: "pointer",
              }}
            />
          </button>
          <span style={{ alignContent: "center" }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="previbutton"
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: 30,
                cursor: "pointer",
              }}
            />{" "}
          </button>
        </div>

        {/* Profile Dialog */}
        <Dialog
          open={state.isProfilePopupOpen}
          onClose={handleCloseProfilePopup}
          PaperProps={{
            style: {
              width: "90%",
              maxWidth: "400px",
              background: "white",
              borderRadius: "60px",
            },
          }}
        >
          <DialogTitle>Property Details </DialogTitle>
          <DialogContent>
            {state.isProfileLoading ? (
              <div style={{ textAlign: "center", padding: 20 }}>
                <img
                  src={Loader}
                  alt="Loading..."
                  style={{ width: 50, height: 50 }}
                />
              </div>
            ) : state.selectedProfileData ? (
              <div className="image-container">
                <img
                  src={`${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`}
                  alt=""
                  className="profile-image"
                />
                <p style={{ marginTop: "10px" }}>
                  <strong>Owner Name:</strong>{" "}
                  {state.selectedProfileData.owner_name}
                </p>
                <p style={{ marginTop: "10px" }}>
                  <strong>Property Name:</strong>{" "}
                  {state?.selectedProfileData?.property_details?.property_name}
                </p>
                <p style={{ marginTop: "10px" }}>
                  <strong>Country:</strong>{" "}
                  {state?.selectedProfileData?.property_details?.country}
                </p>
                <p style={{ marginTop: "10px" }}>
                  <strong>Location:</strong>{" "}
                  {state?.selectedProfileData?.property_details?.street_address}
                </p>

                {/* Add more details as needed */}
              </div>
            ) : (
              <p>No profile data available.</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseProfilePopup}
              style={{
                backgroundColor: "#F87171",
                color: "white",
                borderRadius: "5px",
                padding: "10px 20px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                width: "20%",
                margin: "0 auto",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#E63946")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#F87171")}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default CencelBookingUserList;
