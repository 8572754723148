import React, { useEffect } from "react";
import "./chat-list.css";
import BaseUrl from "../../Server/BaseUrl";

const ChatList = ({ chats, onChatSelect }) => {
  // Log the chats array to the console whenever it updates
  useEffect(() => {
    console.log("Chats Array:", chats);
  }, [chats]);

  return (
    <div className="custom-chat-container">
      {chats.map((chat) => {
        // Sanitize the profile_url to remove 'http://localhost:4000' if it exists
        const sanitizedProfileUrl = chat.profile_url

        return (
          <div
            key={chat.user_id}
            className="custom-chat-item"
            onClick={() => onChatSelect(chat.id)}
          >
            {/* Display the profile image */}
            <img
              src={`${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`}
              alt=""
              className="custom-avatar"
            />

            {/* Display the chat details */}
            <div className="flex-1">
              <h4 className="custom-chat-name">
                {chat.firstName} {chat.lastName}
              </h4>
              <p className="custom-last-message">{chat.property_name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatList;
