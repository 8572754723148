import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActiveBookingList from "./Components/Deshbord/ActiveBookingList";
import HomeHeader from "./HomeComonent/HomeHeader";
import CencelBookingList from "./Components/Deshbord/CencelBookingList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'; // Import the bars icon
import BaseUrl from './Server/BaseUrl';
import './BookingHistory.css';


export default function BookingHistory() {
  const { userid } = useParams();
  const [state, setState] = useState({
    activeBt: 'active_booking',
    apiData: { Histrory: [] },
    loading: true,
    error: null,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility

  useEffect(() => {
    fetchData();
  }, [userid, state.activeBt]);

  const fetchData = async () => {
    const API_URL = `${BaseUrl.BaseUrl}/${state.activeBt}/${userid}`;

    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setState(prevState => ({
        ...prevState,
        apiData: data.message || [], // Adjust according to your API response structure
        loading: false,
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error: error.message,
        loading: false,
      }));
      alert(error.message);
    }
  };

  const handleBookingTypeChange = (bookingType) => {
    setState(prevState => ({
      ...prevState,
      activeBt: bookingType,
    }));
    setIsSidebarOpen(false); // Close sidebar after selecting an option
  };

  return (
    <div>
      <HomeHeader />
      <div className="booking-history">
        {/* FontAwesomeIcon to toggle sidebar */}
        <FontAwesomeIcon
          icon={faBars}
          className="bars-icon"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        />
        <div className={`sidebar1 ${isSidebarOpen ? 'open' : ''}`}>
          <div className="sidebar1-header"><label >Booking</label></div>
          <div className="sidebar1-menu">
            <label className="menu-item">All Booking</label>
            <a
              onClick={() => handleBookingTypeChange('active_booking')}
              className={`menu-item ${state.activeBt === 'active_booking' ? 'active' : ''}`}
            >
              Active Bookings
            </a>
            <a
              onClick={() => handleBookingTypeChange('cancel_booking')}
              className={`menu-item ${state.activeBt === 'cancel_booking' ? 'active' : ''}`}
            >
            Cancelled Bookings
            </a>
          </div>
        </div>
        <div className="content">
          {/* Display ActiveBookingList or CencelBookingList based on activeBt */}
          {state.activeBt === 'active_booking' && <ActiveBookingList />}
          {state.activeBt === 'cancel_booking' && <CencelBookingList />}
        </div>
      </div>
    </div>
  );
}

