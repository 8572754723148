import React, { useState } from 'react';
import './Signup.css';
import logo from '../screens/Image/Group.png';
import LogoText from '../assets/LogoText.png';
import Facebook from '../assets/Face.png';
import Faceboos from '../assets/Faceboos.png';
import Twitter from '../screens/Image/Twitter.png';
import Instagram from '../screens/Image/Instagram.png';
import Google from '../assets/Goo.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import BaseUrl from './Server/BaseUrl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { red } from '@mui/material/colors';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../firebaseConfig';

export default function UserSignup() {
    let navigate = useNavigate();
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [dob, setDob] = useState("");
    const [confirm_password, setConfirm_password] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const validatePassword = (password) => {
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numericRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        return (
            uppercaseRegex.test(password) &&
            lowercaseRegex.test(password) &&
            numericRegex.test(password) &&
            specialCharRegex.test(password) &&
            password.length >= 6
        );
    };

    const SignupFun = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address.');
            return;
        }
        // Validate password
        if (!validatePassword(password)) {
            setErrorMessage('Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character.');
            return;
        }

        if (password !== confirm_password) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        let data = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            dob: dob,
            confirm_password: confirm_password,
        };

        fetch(BaseUrl.BaseUrl + '/auth/signup', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson?.error) {
                    alert(JSON.stringify(responseJson?.error));
                } else {
                    console.log(responseJson);
                    localStorage.setItem('token', responseJson.authToken);
                    localStorage.setItem('user_id', responseJson.user_id);

                    if (responseJson.message === 'User created successfully') {
                        navigate('/userlogin');
                    } else {
                        alert(responseJson.message);
                        // alert('Unexpected response from server.');
                    }
                }
            })
            .catch((error) => {
                alert(JSON.stringify(error));
                setErrorMessage('An error occurred: ' + error.message);
                console.error(error);
            });
    };


     const handleSignInWithGoogle = async () => {
        try {
          const result = await signInWithPopup(auth, googleAuthProvider);
    
          const token = await result.user.getIdToken();
    
          const response = await fetch(`${BaseUrl.BaseUrl}/auth/google-signin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            console.log('User signed in:', data.user);
            localStorage.setItem('token', result.user.accessToken);
            localStorage.setItem('user_id', result.user.uid);
    
            //Check if the user has already accepted Terms & Conditions
            const hasSeenTerms = localStorage.getItem('hasSeenTerms');
            if (!hasSeenTerms) {
              localStorage.setItem('hasSeenTerms', 'true'); // Set the flag
              navigate("/TermsConditionuser"); 
            }
            else{
              navigate("/");
            }
            console.log("Result : ", result.user.uid);
          }
          else {
            console.error('Error:', data.message);
          }
        } catch (error) {
          console.error('Error during Google Sign-In:', error);
        }
      };

    return (
        <div className='Main-Container'>
            <div className='Container'>
                <div className="row">
                    <div className="col-md-6">
                        <div className='signup-img'>
                            <img src={require('../assets/Rectangle 61.png')} alt='' />
                            <div className='detail' style={{ padding: 20 }}>
                                <div style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <Link to='/' style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <img style={{ width: 45, height: 40, marginTop: 5 }} src={logo} alt='' />
                                        <img src={LogoText} alt='' />
                                    </Link>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <a href='https://www.facebook.com/profile.php?id=61557976032264&mibextid=ZbWKwL'>
                                            <img style={{ width: 25, height: 25, marginRight: 10 }} src={Faceboos} alt='' />
                                        </a>
                                        <a href='https://x.com/caribbeaneaze?t=2E6AWgzQswNNT2x_gdjYLw&s=08'>
                                            <img style={{ width: 25, height: 25, marginRight: 10 }} src={Twitter} alt='' />
                                        </a>
                                        <a href='https://www.instagram.com/caribbeaneaze?igsh=MWZ2eTR0M3ByZ21h'>
                                            <img style={{ width: 25, height: 25, marginRight: 10 }} src={Instagram} alt='' />
                                        </a>
                                    </div>
                                </div>
                                <div className='signup-descap'>
                                    <p>
                                        Welcome to Caribbeaneaze – A Free Mind to Your Journey<br />
                                        Discover our collection of Caribbean properties, from luxurious villas and cozy cottages to beachfront homes and budget-friendly stays. Whether you seek tranquility or adventure, Caribbeaneaze connects you with unique hosts and unforgettable experiences<br />
                                        Log in or create an account to start your journey. Follow us on social media for the latest listings and offers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div style={{ width: '85%', margin: '0 auto' }}>
                            <div className='top-log' style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginTop: 25 }}>
                                <p style={{ fontWeight: '700', fontSize: 30, color: '#000' }}>Registration</p>
                                <div className='socical-mo' style={{ display: 'flex', flexDirection: 'row', borderColor: '#C4C4C4', backgroundColor: "#F8FAFC", width: '100%', height: 40, borderRadius: 10, alignItems: 'center', justifyContent: 'flex-start', padding: 10, justifyContent: 'center' }} onClick={() => { handleSignInWithGoogle() }} >
                                    <img style={{ width: 25, height: 25, marginLeft: 10 }} src={Google} alt="" />
                                    <label style={{ marginLeft: 10, fontSize: 16, color: '#474554', cursor: "pointer" }}>Continue with Google</label>
                                </div>
                            </div>
                            <div className='or d-flex mt-3'>
                                <div style={{ borderBottom: '1px solid #a4a4a4' }}></div>
                                <h6 >or</h6>
                                <div style={{ borderBottom: '1px solid #a4a4a4' }}></div>
                            </div>
                        </div>
                        <div className='fieldtext' style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between', gap: 10, padding: "0px 20px" }}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={first_name}
                                onChange={e => setFirst_name(e.target.value)}
                            />
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={last_name}
                                onChange={e => setLast_name(e.target.value)}
                            />
                        </div>
                        <div className='fieldtext' style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between', gap: 10, padding: "0px 20px" }}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <TextField
                                label="Date of Birth"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="date"
                                value={dob}
                                onChange={e => setDob(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: new Date().toISOString().split("T")[0], // Disable future dates
                                }}
                            />


                        </div>
                        <div className='fieldtext' style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between', gap: 10, padding: "0px 20px" }}>
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton style={{ width: 30, height: 30, background: "none" }}
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label="Confirm Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirm_password}
                                onChange={e => setConfirm_password(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton style={{ width: 30, height: 30, background: "none" }}
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <button onClick={SignupFun} style={{ background: '#F15A29', width: '100%', height: 55, marginRight: 20, marginLeft: 20, borderWidth: 0, borderRadius: 8, fontSize: 20, color: 'white', fontWeight: '500' }} className=''>
                                Sign Up
                            </button>
                        </div>
                        <div className='accounr-reg' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 12, marginBottom: 50 }}>
                            <label style={{ fontSize: 16, color: '#0F172A', fontWeight: 500 }}>Already have an account?</label>
                            <NavLink to='/userlogin' style={{ backgroundColor: 'white', fontSize: 16, border: 'none', color: '#F15A29', padding: 5 }}>Sign In</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
