import React, { useState } from "react";
import "./Signup.css";
import LogoText from "../assets/LogoText.png";
import logo from "../screens/Image/Group.png";
import Faceboos from "../assets/Faceboos.png";
import Twitter from "../screens/Image/Twitter.png";
import Instagram from "../screens/Image/Instagram.png";
import Google from "../assets/Goo.png";
import reCAPTCHA from "../screens/Image/reCAPTCHA.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BaseUrl from "./Server/BaseUrl";
import ForgotPasswordModal from "./ForgotPasswordModal";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { signInWithPopup } from "firebase/auth";
import { auth, googleAuthProvider } from "../firebaseConfig";

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 2 * 24 * 60 * 60 * 1000); // Changed to two days
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [key, val] = cookie.trim().split("=");
    if (key === name) return val;
  }
  return null;
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRobotChecked, setIsRobotChecked] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const loginMethod = () => {
    if (!isRobotChecked) {
      setError("Please verify you are not a robot.");
      return;
    }
    setError("");
    const data = {
      email,
      password,
    };
    fetch(BaseUrl.BaseUrl + "/auth/login", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("Login Response:", responseJson); // Log the response to the console

        // Check if the user is blocked
        if (responseJson?.blocked) {
          alert("You have been blocked due to Policy Violation");
          return; // Exit the function if the user is blocked
        }

        if (responseJson?.error) {
          alert(JSON.stringify(responseJson?.error));
        } else {
         localStorage.setItem("user_id", responseJson.user_id);

          const today = new Date().toISOString().split("T")[0]; // Get today's date
          const hasSeenTermsToday = getCookie("hasSeenTermsToday"); // Check the cookie

          // Check if the user has already seen the Terms and Conditions
          const becomeAHostLogin = localStorage.getItem("become_a_host_login");
          if (becomeAHostLogin === "Host") {
            navigate("/Registration");
          } else if (!hasSeenTermsToday || hasSeenTermsToday !== today) {
            setCookie("hasSeenTermsToday", today, 1);
            navigate("/TermsConditionuser");
          } else {
            const userId = localStorage.getItem("user_id");

            navigate(`/dashboard/${userId}`);
          }
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handlePasswordChange = (email, newPassword, confirmPassword) => {
    const data = {
      email: email,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };

    fetch(BaseUrl.BaseUrl + "/auth/change_password", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson?.error) {
          alert(JSON.stringify(responseJson?.error));
        } else {
          alert(responseJson.message);
          setModalIsOpen(false);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);

      const token = await result.user.getIdToken();

      const response = await fetch(`${BaseUrl.BaseUrl}/auth/google-signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("User signed in:", data.user);
        localStorage.setItem("token", result.user.accessToken);
        localStorage.setItem("user_id", result.user.uid);
        navigate("/TermsCondition");
        // console.log("Result : ", result.user.uid);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };

  return (
    <div className="Main-Container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="signup-img">
              <img style={{}} src={require("../assets/Rectangle 61.png")} />
              <div className="detail" style={{ padding: 20 }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: 45, height: 40, marginTop: 5 }}
                      src={logo}
                      alt=""
                    />
                    <img src={LogoText} alt="" />
                  </Link>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <a href="https://www.facebook.com/profile.php?id=61557976032264&mibextid=ZbWKwL">
                      {" "}
                      <img
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        src={Faceboos}
                        alt=""
                      />
                    </a>
                    <a href="https://x.com/caribbeaneaze?t=2E6AWgzQswNNT2x_gdjYLw&s=08">
                      {" "}
                      <img
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        src={Twitter}
                        alt=""
                      />
                    </a>
                    <a href="https://www.instagram.com/caribbeaneaze?igsh=MWZ2eTR0M3ByZ21h">
                      {" "}
                      <img
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        src={Instagram}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="signup-descap">
                  <p>
                    Welcome to Caribbeaneaze – A Free Mind to Your Journey
                    <br />
                    Discover our collection of Caribbean properties, from
                    luxurious villas and cozy cottages to beachfront homes and
                    budget-friendly stays. Whether you seek tranquility or
                    adventure, Caribbeaneaze connects you with unique hosts and
                    unforgettable experiences
                    <br />
                    Log in or create an account to start your journey. Follow us
                    on social media for the latest listings and offers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div style={{ width: "85%", margin: "0 auto" }}>
              <div
                className="top-log"
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <p style={{ fontWeight: "700", fontSize: 30, color: "#000" }}>
                  Login
                </p>
                <div
                  className="socical-mo"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderColor: "#C4C4C4",
                    backgroundColor: "#F8FAFC",
                    width: "100%",
                    height: 40,
                    borderRadius: 10,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    handleSignInWithGoogle();
                  }}
                >
                  <img
                    style={{ width: 25, height: 25, marginLeft: 10 }}
                    src={Google}
                    alt=""
                  />
                  <label
                    style={{
                      marginLeft: 10,
                      fontSize: 20,
                      color: "#474554",
                      cursor: "pointer",
                    }}
                  >
                    Continue with Google
                  </label>
                </div>
              </div>
              <div className="or d-flex mt-3">
                <div style={{ borderBottom: "1px solid #a4a4a4" }}></div>
                <h6>or</h6>
                <div style={{ borderBottom: "1px solid #a4a4a4" }}></div>
              </div>
              <div
                className="social-media"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 10,
                  alignItems: "center",
                }}
              >
                {/* <div style={{ display: 'flex', flexDirection: 'row', borderColor: '#C4C4C4', backgroundColor: "#F8FAFC", width: 'auto', height: 45, borderRadius: 10, alignItems: 'center', justifyContent: 'flex-start', padding: 10 }}>
                  <img style={{ width: 25, height: 25, marginLeft: 10 }} src={Facebook} alt="" />
                  <label style={{ marginLeft: 10, fontSize: 20, color: '#474554' }}>Facebook</label>
                </div> */}
              </div>
              <div style={{ height: "100%" }}>
                <div style={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    style={{ marginBottom: 15 }}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              width: 30,
                              height: 30,
                              background: "none",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 8,
                  }}
                >
                  <button
                    style={{
                      fontSize: 14,
                      color: "#0F172A",
                      fontWeight: "500",
                      alignSelf: "flex-end",
                      marginTop: 5,
                      background: "none",
                      width: 150,
                      border: "none",
                    }}
                    onClick={() => setModalIsOpen(true)}
                  >
                    Forgot password?
                  </button>
                </div>
                <div
                  className="robot"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginTop: 10,
                    height: 75,
                    boxShadow: `1px 1px 2px 2px ${"#D3D3D3"}`,
                    background: "#F9F9F9",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{ width: 20, height: 25 }}
                      type="checkbox"
                      onChange={(e) => setIsRobotChecked(e.target.checked)}
                    />
                    <p
                      style={{
                        fontSize: 18,
                        padding: "0px  10px",
                        color: "#000",
                        marginBottom: "0",
                      }}
                    >
                      I'm not a robot
                    </p>
                  </div>
                  <img
                    style={{ width: 70, height: 65, marginRight: 10 }}
                    src={reCAPTCHA}
                    alt=""
                  />
                </div>

                {error && (
                  <p style={{ color: "red", marginTop: 10 }}>{error}</p>
                )}

                <button
                  onClick={loginMethod}
                  style={{
                    background: "#F15A29",
                    width: "100%",
                    height: 45,
                    marginTop: 25,
                    borderWidth: 0,
                    borderRadius: 8,
                    fontSize: 22,
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  Login
                </button>
                <div
                  className="accounr-reg"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 12,
                    marginBottom: 50,
                  }}
                >
                  <label
                    style={{
                      fontSize: 16,
                      color: "#0F172A",
                      fontWeight: "500",
                    }}
                  >
                    Don't have an account?
                  </label>
                  <NavLink
                    to="/signup"
                    style={{
                      backgroundColor: "white",
                      fontSize: 16,
                      border: "none",
                      color: "#F15A29",
                      padding: 5,
                    }}
                  >
                    Register
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Forgot password modal */}
      <ForgotPasswordModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onPasswordChange={handlePasswordChange}
      />
    </div>
  );
}

export default Login;
