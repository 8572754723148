import React, { useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useParams } from 'react-router-dom';
import BaseUrl from '../../Server/BaseUrl';
import './CustomCalendar.css';

const CustomCalendar = () => {
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [disabledDates, setDisabledDates] = useState([]);
    const property_Id = useParams();
    const propertyId = property_Id.propertyId;

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
    };

 useEffect(() => {
    if (propertyId) {
      fetch(`${BaseUrl.BaseUrl}/property_availabilities/${propertyId}`)
        .then((response) => response.json())
        .then((data) => {
          const unavailableDates = (
            data?.property?.availabilityDates || []
          ).map((entry) => {
            const utcDate = new Date(entry.date);
            return new Date(
              utcDate.getUTCFullYear(),
              utcDate.getUTCMonth(),
              utcDate.getUTCDate()
            );
          });
          setDisabledDates(unavailableDates);
        })
        .catch((error) => {
          console.error("Error fetching property data:", error);
        });
    }
  }, [propertyId]);


    const isDisabledDate = (date) => {
        return disabledDates.some((disabledDate) => disabledDate.toDateString() === date.toDateString());
    };

    return (
        <div className='calendar'>
            <DateRange
                onChange={handleSelect}
                months={window.innerWidth > 600 ? 1 : 1}
                direction="horizontal"
                disabledDates={disabledDates} 
                minDate={new Date()}// Disable unavailable dates
                dayClassName={(date) => (isDisabledDate(date) ? 'red-date' : '')}
            />
        </div>
    );
};

export default CustomCalendar;
