import React, { useState, useEffect, useContext } from "react";
import "./dropdown.css";
import BaseUrl from "../../Server/BaseUrl";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { io } from "socket.io-client";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

// Connect to the Socket.IO server
const socket = io(BaseUrl.BaseUrl, {
  transports: ["websocket"],
});

const Dropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(true);
  const user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const { unreadCount, setUnreadCount } = useContext(NotificationContext);
  const [visibleNotifications, setVisibleNotifications] = useState(5); // Initial number of notifications to show
  const [activeTab, setActiveTab] = useState("Read"); // Active tab: Seen or Unseen
  const [loading, setLoading] = useState(true); // New loading state
  const [error, setError] = useState(false);

  // const { userid } = useParams();
  // const [booking, setBookingdata] = useState([]);
  // console.log("user ids ===", userid)
  // const [activeBooking, setActiveBooking] = useState([]);
  // useEffect(() => {
  //   fetch(`${BaseUrl.BaseUrl}/booking_history/${user_id}`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch user data");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setBookingdata(data?.message || []);
  //     })
  //     .catch((error) => {
  //       setError(error.message);
  //     });
  // }, [user_id]);

  // useEffect(() => {
  //   fetchData();
  // }, [userid]);

  // const fetchData = async () => {
  //   const API_URL = `${BaseUrl.BaseUrl}/active_booking/${userid}`;

  //   try {
  //     const response = await fetch(API_URL);
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     const data = await response.json();

  //     const filteredData =
  //       data?.message?.active_bookings?.filter(
  //         (booking) =>
  //           booking.status === "in Progress" ||
  //           booking.status === "Completed" ||
  //           booking.status === "cancel by host" ||
  //           booking.status === "cancel by user"
  //       ) || [];

  //     setActiveBooking(filteredData);
  //     autoMarkCompleted(filteredData);
  //   } catch (error) {
  //     console.error(
  //       "An error occurred while marking the booking complete",
  //       error
  //     );
  //   }
  // };

  // console.log("data1111", activeBooking);

  // const getStatusClass = (status) => {
  //   if (status === "in Progress") return "status-confirmed";
  //   if (status === "in Progress") return "status-confirmed";
  //   return "status-cancelled";
  // };

  // const getTimeDifference = (notificationTime) => {
  //   const currentTime = new Date();
  //   const notificationDate = new Date(notificationTime);
  //   const diffInSeconds = Math.floor((currentTime - notificationDate) / 1000);

  //   if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
  //   if (diffInSeconds < 3600)
  //     return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  //   if (diffInSeconds < 86400)
  //     return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  //   return `${Math.floor(diffInSeconds / 86400)} days ago`;
  // };
  // const renderBooking = () => {
  //   return booking.map((b, index) => (
  //     <div key={index} className={`notification ${getStatusClass(b?.status)}`}>
  //       <p>
  //         Hi <strong>{b?.user_name?.split(" ")[0]}</strong>, your booking date
  //         is <strong>{b?.entry_date}</strong> to <strong>{b?.exit_date}</strong>
  //         .
  //       </p>
  //       <small className="notification-time" style={{ color: "gray" }}>
  //         {getTimeDifference(b?.booking_date)}
  //       </small>
  //     </div>
  //   ));
  // };

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetch(
          `${BaseUrl.BaseUrl}/api/owner/notifications?user_id=${user_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch notifications");
        }
        const data = await response.json();
        setNotifications(data?.notifications || []);
        setUnreadCount(data?.unreadCount || 0);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching notifications:", error.message);
        setLoading(false);
        setError(true);
      }
    };

    if (user_id) {
      fetchNotifications();
    }
  }, [user_id, setUnreadCount]);

  useEffect(() => {
    // Listen for property updates (property decisions made by host)
    socket.on("propertyUpdate", (notification) => {
      console.log("Received property update:", notification);
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
      setUnreadCount((prev) => prev + 1); // Increase unread count
    });

    // Cleanup on component unmount
    return () => {
      socket.off();
      socket.disconnect();
    };
  }, [user_id, setUnreadCount]);

  const fetchNotificationsByTab = async () => {
    try {
      const tabFilter = tab === "Unread" ? "unread" : "read"; // Adjust filter based on the tab
      const response = await fetch(
        `${BaseUrl.BaseUrl}/api/owner/notifications?user_id=${user_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
      const data = await response.json();
      setNotifications(data?.data || []);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    fetchNotificationsByTab(); // Fetch data when switching tabs
  };

  // Mark notification as read
  const markAsRead = async (notificationId) => {
    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/api/owner/notifications/mark_as_read`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ notification_id: notificationId }),
        }
      );

      if (response.ok) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((noti) =>
            noti._id === notificationId ? { ...noti, read: true } : noti
          )
        );
        setUnreadCount((prev) => Math.max(prev - 1, 0)); // Avoid negative counts
        // Reload the page after marking as read
        window.location.reload();
      }
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const loadMoreNotifications = () => {
    setVisibleNotifications((prev) => prev + 5);
  };

  const handleNotificationClick = (notifications) => {
    switch (notifications.type) {
      case "property":
        navigate(`/mylisting/${user_id}`);
        break;

      case "withdrawal":
        navigate(`/earning/${user_id}`);
        break;

      case "booking":
        navigate(`/booking/${user_id}`);
        break;
    }
  };

  const renderNotifications = (
    notifications,
    handleNotificationClick,
    visibleNotifications
  ) => {
    const createCustomMessage = (notifications) => {
      let customMessage = "";
      let fullDate = notifications.booking_date;
      let formattedDate = fullDate.split(" ")[0];
      console.log(formattedDate);
      switch (notifications.type) {
        case "property":
          if (notifications.status) {
            switch (notifications.status) {
              case "accept":
                customMessage = ` Hi ${
                  notifications?.user_name?.split(" ")[0]
                }, your request to register property named ${
                  notifications?.property_name
                } has been ${notifications?.status}ed By the Host.`;
                break;

              case "cancel":
                if (notifications.reason) {
                  switch (notifications.reason) {
                    case "Other's":
                      customMessage = `Hi ${
                        notifications?.user_name?.split(" ")[0]
                      }, your request to register property named ${
                        notifications?.property_name
                      } has been ${
                        notifications?.status
                      }ed By the Host. Due to because ${notifications?.info}.`;
                      break;
                    default:
                      customMessage = `Hi ${
                        notifications?.user_name?.split(" ")[0]
                      }, your request to register property named ${
                        notifications?.property_name
                      } has been ${
                        notifications?.status
                      }ed By the Host. Due to ${notifications?.reason}.`;
                      break;
                  }
                }
                break;
            }
          }
          break;
        case "booking":
          if (notifications.booking_status) {
            // Generate message based on booking_status
            const nightLabel = notifications.nights > 1 ? "night's" : "night";
            switch (notifications.booking_status) {
              case "Completed":
                customMessage = `Congratulations!!! ${notifications.user_name} your booking of ${notifications.property_name} from ${formattedDate} to ${notifications.exit_date} of total ${notifications.nights} ${nightLabel} with total amount $${notifications.amount} has been accepted by the Host.`;
                break;
              case "cancel by host":
                customMessage = `Hi ${notifications.user_name} your booking of ${notifications.property_name} from ${notifications.entry_date} to ${notifications.exit_date} of total ${notifications.nights} ${nightLabel} with total amount $${notifications.amount} has been rejected by the Host due to following reason : ${notifications.reason}`;
                break;
              case "cancel by user":
                customMessage = `The User ${notifications.user_name} has cancelled their booking for the property named ${notifications.property_name} on date ${formattedDate} for the dates starting from ${notifications.entry_date} to ${notifications.exit_date} which had total amount of $${notifications.amount} due to follwing reason :${notifications.reason}`;
                break;
              case "in Progress":
                customMessage = `Congratulations!!! a new booking has been made on your property named ${notifications.property_name} on date ${formattedDate} for the dates starting from ${notifications.entry_date} to ${notifications.exit_date} of total ${notifications.nights} ${nightLabel}  which has total amount of $${notifications.amount} by  ${notifications.user_name}.`;
                break;
            }
          }
          break;

        case "withdrawal":
          if (notifications.withdrawal_type) {
            switch (notifications.withdrawal_type) {
              case "paypal":
                if (notifications.status) {
                  switch (notifications.status) {
                    case "Paid":
                      customMessage = `Your ${notifications.withdrawal_type} withdrawal request of amount $${notifications.amount} has accepted by the Admin. `;
                      break;
                    case "Rejected":
                      customMessage = `Your ${notifications.withdrawal_type} withdrawal request of amount $${notifications.amount} has rejected by the Admin. `;
                      break;
                  }
                }
                break;

              case "bank":
                if (notifications.status) {
                  switch (notifications.status) {
                    case "Paid":
                      customMessage = `Your ${notifications.withdrawal_type} withdrawal request of amount $${notifications.amount} has accepted by the Admin. `;
                      break;
                    case "Rejected":
                      customMessage = `Your ${notifications.withdrawal_type} withdrawal request of amount $${notifications.amount} has rejected by the Admin. `;
                  }
                }
                break;
            }
          }
      }
      return customMessage;
    };

    if (error || notifications.length === 0) {
      return (
        <SkeletonWrapper
          containerClass="Skeleton-notifications-container"
          items={[
            {className : 'skeleton-header'},
            {className : 'skeleton-head'},
            {className : 'skeleton-head'},
          ]}
        />
      );
    }

    return loading ? (
        <SkeletonWrapper
          containerClass="Skeleton-notifications-container"
          items={[
            {className : 'skeleton-header'},
            {className : 'skeleton-head'},
            {className : 'skeleton-head'},
          ]}
        />
    ) : (
      notifications.slice(0, visibleNotifications).map((noti, index) => (
        <>
          <div
            key={index}
            className={`notification ${noti.read ? "read" : "unread"}`}
          >
            <p
              onClick={() => {
                markAsRead(noti._id);
                handleNotificationClick(noti);
              }}
            >
              {createCustomMessage(noti)}
            </p>
          </div>
        </>
      ))
    );
  };

  const filteredNotifications =
    activeTab === "Read"
      ? notifications.filter((noti) => noti.read)
      : notifications.filter((noti) => !noti.read);

  return (
    // onMouseLeave={handleMouseLeave}
    <div className="dropdown">
      {isDropdownVisible && (
        <div className="notch">
          <div className="dropdown-content show">
            <h3>Notifications</h3>
            <div className="notification-tabs">
              <p
                className={`tab-button ${activeTab === "Read" ? "active" : ""}`}
                onClick={() => handleTabSwitch("Read")}
              >
                Read
              </p>
              <p
                className={`tab-button ${
                  activeTab === "Unread" ? "active" : ""
                } ${unreadCount > 0 ? "blinking-dot" : ""}`}
                onClick={() => handleTabSwitch("Unread")}
              >
                Unread
              </p>
            </div>
            <div className="notifications-list">
              <div>
                {/* {renderBooking()} */}
                {renderNotifications(
                  filteredNotifications,
                  handleNotificationClick,
                  visibleNotifications
                )}
              </div>
            </div>
            <div className="load-more" onClick={loadMoreNotifications}>
              Load More Notifications
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
