import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios for API calls
import "./editform.css";
import HomeHeader from "../HomeHeader";
import BaseUrl from "../../Server/BaseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import {io} from 'socket.io-client';
 
 
const Extra = [
  { id: 1, item: "Rafting", description: "", number_of_guest: "", price: "" },
  {
    id: 2,
    item: "Exotic Food",
    description: "",
    number_of_guest: "",
    price: "",
  },
  {
    id: 3,
    item: "Pick & Drop",
    description: "",
    number_of_guest: "",
    price: "",
  },
  { id: 4, item: "BBQ", description: "", number_of_guest: "", price: "" },
  { id: 5, item: "Breakfast", description: "", number_of_guest: "", price: "" },
];
 
 
// Connect to the Socket.IO server
const socket = io(BaseUrl.BaseUrl, {
  transports: ['websocket'],
});
 
const PropertyForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const propertyData = location.state?.propertyData || {};
  // const [selectedExtra, setSelectedExtra] = useState(null);
  const [extraServices, setExtraServices] = useState([...Extra]);
  const [selectedExtra, setSelectedExtra] = useState(extraServices[0].id);
  const [showModal, setShowModal] = useState(false);
  const [newExtraService, setNewExtraService] = useState("");
  const [userName, setUserName] = useState("");
 
  // const [formFields, setFormFields] = useState({ number_of_guest: '', price: '', description: '' });
 
  const [formData, setFormData] = useState({
    property_name: propertyData.property_name || "",
    city: propertyData.city || "",
    country: propertyData.country || "",
    guest_count: propertyData.guest_count || "",
    bedroom_count: propertyData.bedroom_count || "",
    bathroom_count: propertyData.bathroom_count || "",
    price_per_night: propertyData.price_per_night || "",
    select_view: propertyData.select_view || "",
    property_type: propertyData.property_type || "",
    property_description: propertyData.property_description || "",
  });
  const [propertyImages, setPropertyImages] = useState([]);
  const userId = localStorage.getItem("user_id");
 
  useEffect(() => {
    // Fetch user name from the API when the component is mounted
    const fetchUserName = async () => {
      try {
 
        if (!userId) {
          console.log("User ID not found in local storage");
          return;
        }
 
        const response = await fetch(`${BaseUrl.BaseUrl}/api/user/${userId}`);
        const apiData = await response.json();
 
        if (apiData.success) {
          const user_name = `${apiData?.data?.first_name}`;
          setUserName(user_name); // Set user name to state
          console.log("User Name from API: ", user_name);
        } else {
          console.log("Failed to fetch user name:", apiData.msg);
        }
      } catch (error) {
        console.log("Error fetching user name:", error);
      }
    };
 
    fetchUserName();
  }, []);
 
  useEffect(() => {
    // Prepopulate extra services if any exist in propertyData
    if (propertyData.extra_service && propertyData.extra_service.length > 0) {
      setExtraServices(propertyData.extra_service);
    }
  }, [propertyData]);
 
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
 
  console.log("edit form list ", extraServices);
 
  // Handle file uploads
  const handleFileChange = (e) => {
    setPropertyImages(e.target.files);
  };
 
  const handleExtraChange = (id, field, value) => {
    setExtraServices((prev) =>
      prev.map((service) =>
        service.id === id ? { ...service, [field]: value } : service
      )
    );
  };
 
  // Add a custom extra service
  const handleAddExtraService = () => {
    if (newExtraService.trim()) {
      const newService = {
        id: extraServices.length + 1,
        item: newExtraService,
        description: "",
        number_of_guest: "",
        price: "",
      };
      setExtraServices((prev) => [...prev, newService]);
      setShowModal(false);
      setNewExtraService("");
      setSelectedExtra(newService.id);
    }
  };
 
  const toggleModal = () => {
    setShowModal((prev) => !prev);
    setNewExtraService("");
  };
 
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    try {
      const form = new FormData();
      form.append("user_id", propertyData.user_id);
      Object.keys(formData).forEach((key) => {
        form.append(key, formData[key]);
      });
      // Append the actual file objects to the FormData
      Array.from(propertyImages).forEach((file) => {
        form.append("property_images", file);
      });
 
      // Filter extra services that have required fields filled
      const filledExtraServices = extraServices.filter(
        (service) =>
          service.number_of_guest || service.price || service.description
      );
 
      // Append filtered extra services
      form.append("extra_service", JSON.stringify(filledExtraServices));
 
      const property_name = formData.property_name;//added this line
      // Emit the notification event without any data
        socket.emit("notification", {
        type: "property",
        userId,
        property_name,
        withdrawal_type: "",
        booking_status: "",
        name:userName,
        read: false,
        createdAt: Date.now(),
      });
      console.log("Notification event emitted"); // Log to console
 
      // Send API request
      const response = await axios.put(
        `${BaseUrl.BaseUrl}/property/${propertyData.property_id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
 
      alert("Property updated successfully");
      console.log(response.data);
      navigate(`/mylisting/${userId}`);
    } catch (error) {
      console.error("Error updating property:", error);
      alert("Failed to update property");
    }
  };
 
  return (
    <>
      <HomeHeader />
      <div className="property-form-container">
        <h1>Property Details</h1>
        <form className="property-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="property_name">Property Name:</label>
            <input
              type="text"
              id="property_name"
              name="property_name"
              value={formData.property_name}
              onChange={handleChange}
              required
            />
          </div>
 
          <div className="form-group">
            <label htmlFor="city">Location (City & Country):</label>
            <div className="location-container">
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                required
              />
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Country"
                disabled
              />
            </div>
          </div>
 
          <div className="form-group single-line">
            <div className="form-field">
              <label htmlFor="guest_count">People Count:</label>
              <input
                type="number"
                id="guest_count"
                name="guest_count"
                min="1"
                value={formData.guest_count}
                onChange={handleChange}
                required
              />
            </div>
 
            <div className="form-field">
              <label htmlFor="bathroom_count">Bedroom Count:</label>
              <input
                type="number"
                id="bathroom_count"
                name="bathroom_count"
                min="1"
                value={formData.bathroom_count}
                onChange={handleChange}
                required
              />
            </div>
 
            <div className="form-field">
              <label htmlFor="bathroom_count">Bathroom Count:</label>
              <input
                type="number"
                id="bathroom_count"
                name="bathroom_count"
                min="1"
                value={formData.bathroom_count}
                onChange={handleChange}
                required
              />
            </div>
          </div>
 
          <div className="form-group">
            <label htmlFor="price_per_night">Price Per Night:</label>
            <input
              type="text"
              id="price_per_night"
              name="price_per_night"
              value={formData.price_per_night}
              onChange={handleChange}
              required
            />
          </div>
 
          <div className="form-group">
            <label htmlFor="select_view">Select View:</label>
            <input
              type="text"
              id="select_view"
              name="select_view"
              value={formData.select_view}
              onChange={handleChange}
              required
            />
          </div>
 
          <div className="form-group">
            <label htmlFor="property_type">Property Type:</label>
            <input
              type="text"
              id="property_type"
              name="property_type"
              value={formData.property_type}
              onChange={handleChange}
              required
            />
          </div>
 
          <div className="form-group">
            <label htmlFor="property_description">Description:</label>
            <textarea
              id="property_description"
              name="property_description"
              rows="4"
              value={formData.property_description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
 
          <div className="form-group">
            <label htmlFor="propertyImages">Property Images (up to 3):</label>
            <input
              type="file"
              id="propertyImages"
              name="property_images"
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
          </div>
 
          {/* Extra Services Section */}
          <div className="form-group">
            <h2>Extra Service</h2>
            <div className="extra-services">
              <div
                className="service-tabs"
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 10,
                  flexWrap: "wrap",
                }}
              >
                {extraServices.map((service) => (
                  <ul key={service.id} type="button" className="">
                    <li
                      style={{
                        background:
                          selectedExtra === service.id ? "#F15A29" : "#F3F4F6",
                        borderRadius: "5px",
                        color:
                          selectedExtra === service.id ? "white" : "darkgray",
                        fontSize: "14px",
                        padding: "10px 15px",
                        marginRight: "10px",
                        listStyle: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectedExtra(service.id)}
                    >
                      {service.item}
                    </li>
                  </ul>
                ))}
                <p
                  onClick={() => setShowModal(true)}
                  style={{
                    width: "20px",
                    height: "40px",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <span style={{ color: "gray", fontSize: "30px" }}>
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      style={{ marginBottom: "20px" }}
                    />
                  </span>
                </p>
              </div>
 
              {/* Selected Service Form */}
              {extraServices.map(
                (service) =>
                  service.id === selectedExtra && (
                    <div key={service.id} className="service-details">
                      <input
                        type="text"
                        placeholder="Number of Guests"
                        value={service.number_of_guest}
                        onChange={(e) =>
                          handleExtraChange(
                            service.id,
                            "number_of_guest",
                            e.target.value
                          )
                        }
                      />
                      <input
                        type="text"
                        placeholder="Price($)"
                        value={service.price}
                        onChange={(e) =>
                          handleExtraChange(service.id, "price", e.target.value)
                        }
                      />
                      <textarea
                        placeholder={`Enter ${service.item} Description`}
                        value={service.description}
                        onChange={(e) =>
                          handleExtraChange(
                            service.id,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )
              )}
            </div>
          </div>
 
          {/* Modal for Adding Custom Extra Service */}
          {/* Modal for Adding New Service */}
          {showModal && (
            <div className="modal-overlay">
              <div className="modal">
                <h4>Add Custom Service</h4>
                <input
                  type="text"
                  placeholder="Service name"
                  value={newExtraService}
                  onChange={(e) => setNewExtraService(e.target.value)}
                />
                <div className="addcencel">
                  <button onClick={handleAddExtraService}>Add</button>
                  <button onClick={() => setShowModal(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}
 
          <button id="id600" type="submit" style={{ width: "30%" }}>
            Submit
          </button>
        </form>
      </div>
    </>
  );
};
 
export default PropertyForm;
 
 