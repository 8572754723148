import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import { useRating } from "../../../RatingContext";
import { useParams } from "react-router-dom";
import BaseUrl from "../../Server/BaseUrl";

const Rating = () => {
  const { averageRating } = useRating(); // Context se rating le raha hai
  const [reviewsCommentCount, setReviewsCommentCount] = useState(0);
  const [error, setError] = useState(null);

  // const property_Id = useParams();
  // const propertyId = property_Id.propertyId;
  // UseParams to get property_id from the URL (for PropertyPage)
  const { propertyId: paramsPropertyId } = useParams();

  // Resolve property_id dynamically (from useParams or localStorage)
  const propertyId = paramsPropertyId || localStorage.getItem("property_id");

  useEffect(() => {
    if (!propertyId) {
      console.error("Property ID is missing. Ensure it is provided.");
      setError("Property ID is missing.");
      return;
    }

    const fetchReviews = async () => {
      try {
        const response = await fetch(`${BaseUrl.BaseUrl}/property/getReviews`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ property_id: propertyId }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();

        if (data?.reviewCount) {
          setReviewsCommentCount(data?.reviewCount); // Update state with the count of reviews
          console.log("Fetched reviews:", data.reviewCount);
        } else {
          console.warn("No reviews found for this property.");
        }
      } catch (err) {
        console.error("Failed to fetch reviews:", err);
        setError(err.message || "Something went wrong.");
      }
    };

    fetchReviews(); // Call the function immediately when the component mounts
  }, [propertyId]);

  // console.log("stored comment count", reviewsCommentCount);
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (averageRating >= i) {
        stars.push(
          <FontAwesomeIcon
            key={i}
            icon={faSolidStar}
            style={{ color: "#FFD700", marginRight: "2px" }}
          />
        );
      } else if (averageRating >= i - 0.5) {
        stars.push(
          <FontAwesomeIcon
            key={i}
            icon={faStarHalfAlt}
            style={{ color: "#FFD700", marginRight: "2px" }}
          />
        );
      } else {
        stars.push(
          <FontAwesomeIcon
            key={i}
            icon={faRegularStar}
            style={{ color: "#FFD700", marginRight: "2px" }}
          />
        );
      }
    }
    return stars;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {renderStars()}
      <span
        style={{
          marginLeft: "10px",
          fontWeight: "bold",
          color: "#333",
          fontSize: "14px",
        }}
      >
        {reviewsCommentCount}
      </span>
      <span style={{ marginLeft: "10px", fontWeight: "bold", color: "#333" }}>
        ({averageRating})
      </span>
    </div>
  );
};

export default Rating;
