import React, { useState, useEffect } from "react";
import "./EarningScreen.css"; // Import responsive CSS
import Sidebar from "./Components/Sidebar";
import HomeHeader from "./HomeComonent/HomeHeader";
import BaseUrl from "./Server/BaseUrl";
import { useParams } from "react-router-dom";
import SkeletonWrapper from "./HomeComonent/Skeleton/SkeletonWrapper";

const Jobs = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState([]);
  const { userid } = useParams();

  useEffect(() => {
    setLoading(true);
    fetch(`${BaseUrl.BaseUrl}/owner/deshboard/${userid}`)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data?.message?.data.filter(
          (job) => job.status.toLowerCase() === "completed"
        );
        setUserData(filteredData);
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching data");
        setLoading(false);
        console.error(err);
      });
  }, [userid]);

  const formatExtraServices = (services) => {
    try {
      const parsedServices = JSON.parse(services);
      return parsedServices.map((service) => service.item).join(", ");
    } catch (error) {
      console.error("Error parsing extra services:", error);
      return "";
    }
  };

  return (
    <div className="earning-screen">
      <HomeHeader />
      <div className="app">
        <Sidebar />
        <div className="jobs-container">
          <div className="jobs-header">
            <h2>Completed Jobs</h2>
          </div>
          {loading ? (
            <SkeletonWrapper
              containerClass="Skeleton-jobs-screen"
              items={[
                { className: "skeleton-header" },
                { className: "skeleton-content" },
                { className: "skeleton-header" },
                { className: "skeleton-content" },
                { className: "skeleton-header" },
                { className: "skeleton-content" },
              ]}
            />
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : userData.length === 0 ? (
            <p className="no-jobs">No completed jobs available</p>
          ) : (
            <table className="jobs-table">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Date & Time</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((job, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "0px 30px 0px 10px ",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {job.nights} Night
                      {job.extra_services &&
                        ", " + formatExtraServices(job.extra_services)}
                      {/* <p className="service-title">{job.service}</p> */}
                      <p>
                        <span style={{ color: "#64748B" }}>
                          Booking by: {job.user_name}
                        </span>
                      </p>
                    </td>
                    <td className="date-time">
                      <p>
                        {job.entry_date}-{job.exit_date}
                      </p>
                    </td>
                    <td className="total-earnings">${job.owner_earnings}</td>
                    <td>
                      <span className={`status ${job.status.toLowerCase()}`}>
                        {job.status.charAt(0).toUpperCase() +
                          job.status.slice(1)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
