import React, { useEffect, useState } from "react";
import "./MyListing.css";
import { useParams } from "react-router-dom";
import SceensHeader from "./Components/Deshbord/ScreensHeader";
import UserProfile from "./Components/Deshbord/UserProfile";
import ListingList from "./Components/Deshbord/ListingList";
import BaseUrl from "./Server/BaseUrl";
import HomeHeader from "./HomeComonent/HomeHeader";
import SkeletonWrapper from "./HomeComonent/Skeleton/SkeletonWrapper";
function MyListing() {
  const { userid } = useParams();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    userProfile: [],
    property: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          BaseUrl.BaseUrl + `/user_profile/${userid}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Data from API for my list:", data);
        setUserData({
          userProfile: data,
          property: data.property_list,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userid]);

  if (loading) {
    return (
      <div>
        <HomeHeader />
        <UserProfile props_data={userData.userProfile} />
        <SkeletonWrapper
          containerClass="Skeleton-listing"
          items={[
            { className: "skeleton-box" },
            { className: "skeleton-box" },
            { className: "skeleton-box" },
          ]}
        />
      </div>
    );
  }

  console.log("user profile data showings ====", userData.property);

  return (
    <>
      <>
        <div>
          {/* <SceensHeader/>
         <SceensHeader /> */}
          <HomeHeader />
          <UserProfile props_data={userData.userProfile} />

          <ListingList props_data={userData.property} loading={loading} />
        </div>
      </>
    </>
  );
}

export default MyListing;
