import React, { useState } from 'react';
import StarRating from './StarRating'; // Adjust the import path if necessary
import { useParams } from 'react-router-dom';
import './ReviewForm.css';
import BaseUrl from '../../Server/BaseUrl';
import Loader from '../../../assets/loader.gif';

const ReviewForm = () => {
  const { propertyId } = useParams();
  const [reviwes, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    if (!reviwes.trim()) {
      newErrors.reviwes = 'Review is required.';
      valid = false;
    }

    if (!rating || rating < 1 || rating > 5) {
      newErrors.rating = 'Rating must be between 1 and 5.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Retrieve user_id from localStorage
    const userId = localStorage.getItem('user_id');

    if (!isLoggedIn) {
      setErrors({ general: 'You must be logged in to submit a review.' });
      return;
    }

    if (validateForm()) {
      setIsLoading(true);
      const startTime = Date.now();

      try {
        const response = await fetch(`${BaseUrl.BaseUrl}/property/addReviews`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id: userId, // Replace with actual user ID from authentication
            property_id: propertyId,
            reviwes,
            rating,
          }),
        });
        const endTime = Date.now();
        const delay = Math.max(2000 - (endTime - startTime), 0);

        setTimeout(async () => {

          if (response.ok) {
            const data = await response.json();
            console.log(`${data}`);
            setReview('');
            setRating(0);
            setSuccessMessage(data.message || 'Review submitted successfully!');
            setErrors({});
            window.location.reload();

          } else {
            const errorData = await response.json();
            setSuccessMessage('');
            setErrors({ general: errorData.error || 'Failed to submit review.' });
          }
          setIsLoading(false);
        }, delay);
      } catch (error) {
        console.error('Error submitting review:', error);
        setSuccessMessage('');
        setErrors({ general: 'An unexpected error occurred.' });
        setIsLoading(false);

      }
    }
  };
  return (
    <div className="review-form-container" style={{ marginBottom: '20px' }}>
      <h2>Post a Review</h2>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errors.general && <div className="error-message">{errors.general}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Rating:</label>
          <StarRating rating={rating} onRatingChange={setRating} />
          {errors.rating && <div className="error-message">{errors.rating}</div>}
        </div>
        <div className="form-group" style={{ paddingLeft: '0' }}>
          <label>Review:</label>
          <textarea
            value={reviwes}
            onChange={(e) => setReview(e.target.value)}
            rows="4"
            cols="100"
            className="review-textarea"
          />
          {errors.reviwes && <div className="error-message">{errors.reviwes}</div>}
        </div>
        <button type="submit" className="review-btn">Submit Review</button>
      </form>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '9999',
          }}
        >
          <img src={Loader} alt="Loading..." />
        </div>
      )}

    </div>
  );
};

export default ReviewForm;


