import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "./List.css";
import "./CencelBookingList.css";
import BaseUrl from "../../Server/BaseUrl";
// import Loader from "../../../assets/loader.gif";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

const CencelBookingList = () => {
  const [cancellationReason, setCancellationReason] = useState("");
  const { userid } = useParams();
  const [state, setState] = useState({
    activeBt: "",
    apiData: [], // Initialize as an empty array
    loading: true,
    error: null,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items to display per page

  useEffect(() => {
    fetchData();
  }, [userid, state.activeBt]);

  const fetchData = async () => {
    const API_URL = `${BaseUrl.BaseUrl}/active_booking/${userid}`;

    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const filteredData =
        data?.message?.active_bookings?.filter(
          (booking) =>
            booking.status === "cancel by host" ||
            booking.status === "cancel by user"
        ) || [];

      setState((prevState) => ({
        ...prevState,
        apiData: filteredData, // Ensure apiData is an array
        loading: false,
      }));
      console.log("data1111", data);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.message,
        loading: false,
      }));
      alert(error.message);
    }
  };

  if (state.loading) {
    return (
      <div className="booking-history">
        <SkeletonWrapper
        containerClass="Skeleton-host-booking-list"
        items={[
          { className: "skeleton-header" },
          { className: "skeleton-heading" },
          { className: "skeleton-content" },
          { className: "skeleton-content" },
          { className: "skeleton-content" },
          { className: "skeleton-content" },
          { className: "skeleton-content" },
          { className: "skeleton-page" },
        ]}
      />
      </div>
    );
  }

  if (state.error) {
    return <div>Error: {state.error}</div>;
  }
  // Pagination logic
  const totalPages = Math.ceil(state.apiData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = state.apiData.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const parseExtraServices = (services) => {
    try {
      return (
        JSON.parse(services)
          ?.map((service) => service.item)
          .join(", ") || ""
      );
    } catch {
      return "";
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
        {" "}
        Booking Cancellation by Host
      </h3>
      <div className="topMain" style={{ width: "100%" }}>
        {currentData.length === 0 ? (
          <p style={{ textAlign: "center" }}>No bookings available</p>
        ) : (
          <div className="table-Page" style={{ overflowX: "auto", }}>
            <table
              className="table-full"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    SERVICE
                  </th>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    Entry Date & Exit Date
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Total
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Status
                  </th>
                  {/* <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "left",
                      }}
                    >
                      {item.nights} Night
                      {item.extra_services && (
                        <> + {parseExtraServices(item.extra_services)}</>
                      )}
                      <br />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          color: "#64748B",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "0px",
                          }}
                        >
                          {" "}
                          Booking by: {item.user_name}
                        </p>
                        <Popup
                          className="popupdoc"
                          trigger={
                            <button
                              style={{
                                background: "none",
                                color: "#F15A29",
                                fontWeight: "bold",
                              }}
                            >
                              View Profile
                            </button>
                          }
                          modal
                          contentStyle={{
                            width: "auto",
                            height:"auto",
                            padding: "20px",
                            borderRadius: 10,
                            zIndex: "10000  ", 
                          }}
                        >
                          {(close) => (
                            <div style={{ textAlign: "right" }}>
                              <button
                                onClick={() => close()}
                                style={{
                                  borderRadius: "50%",
                                  background: "none",
                                  border: "none",
                                  width: 25,
                                  height: 25,
                                }}
                              >
                                <img
                                  style={{
                                    width: 15,
                                    height: 15,
                                    alignItems: "center",
                                  }}
                                  src={require("../../../assets/Icon.png")}
                                />
                              </button>
                              <div
                                id="userdetaip"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <h4>Uploaded Documents</h4>
                                {/* Displaying the user images */}
                                <div id="imgflex">
                                  {item.user_image &&
                                  Array.isArray(item.user_image) &&
                                  item.user_image.length > 0 ? (
                                    item.user_image.map((img, idx) => (
                                      <a
                                        key={idx}
                                        href={`${BaseUrl.BaseUrl}/${img}`}
                                        download={`user_image_${idx}`}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <img
                                          src={`${BaseUrl.BaseUrl}/${img}`}
                                          alt="User"
                                          style={{
                                            width: "200px",
                                            height: "150px",
                                            borderRadius: "10px",
                                            marginBottom: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </a>
                                    ))
                                  ) : item.user_image ? (
                                    <a
                                      href={`${BaseUrl.BaseUrl}/${item.user_image}`}
                                      download="user_image"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <img
                                        src={`${BaseUrl.BaseUrl}/${item.user_image}`}
                                        alt="User"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          borderRadius: "50%",
                                          marginBottom: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </a>
                                  ) : (
                                    <p>No image available</p>
                                  )}
                                </div>

                                <h4>User Details </h4>

                                <p>
                                  <strong>User Name:</strong> {item.user_name}
                                </p>
                                <p>
                                  <strong>Identity Type:</strong>{" "}
                                  {item.identity_type}
                                </p>
                                <p>
                                  <strong>Booking Date:</strong>{" "}
                                  {item.booking_date}
                                </p>
                                <p>
                                  <strong>Entry Date:</strong> {item.entry_date}{" "}
                                  | <strong>Exit Date:</strong> {item.exit_date}
                                </p>
                                <p>
                                  <strong>Country:</strong> {item.country}
                                </p>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.entry_date} to {item.exit_date}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      ${item.owner_earnings}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          border: "none",
                          borderRadius: 5,
                          whiteSpace:"nowrap",
                        }}
                      >
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination Controls */}
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="previbutton"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent2.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: "none",
                cursor: "pointer",
              }}
            />
          </button>
          <span style={{ alignContent: "center" }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="previbutton"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: 30,
                cursor: "pointer",
              }}
            />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CencelBookingList;
