import React, { createContext, useEffect, useState } from "react";
import BaseUrl from "../screens/Server/BaseUrl";
 
export const NotificationContext = createContext();
 
const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);
 
  const fetchNotifications = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await fetch(
        `${BaseUrl.BaseUrl}/api/owner/notifications?user_id=${user_id}`
      );
 
      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
 
      const data = await response.json();
      setUnreadCount(data.unreadCount);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };
 
  useEffect(() => {
    fetchNotifications();
  }, []);
 
  return (
    <NotificationContext.Provider
      value={{ unreadCount, setUnreadCount, fetchNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
 
export default NotificationProvider;
 
 