import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const userId = localStorage.getItem("user_id");

    // If no user ID is found, redirect to login
    if (!userId) {
        return <Navigate to="/login" replace />;
    }

    // Render the children (protected component)
    return children;
};

export default ProtectedRoute;
