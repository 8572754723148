import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import Loader from "../../../assets/loader.gif";
import BaseUrl from "../../Server/BaseUrl";

import "./ActiveBookingList.css";

import { io } from "socket.io-client";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

const ActiveBookingList = ({ propertyData }) => {
  const [cancellationReason, setCancellationReason] = useState("");
  const { userid } = useParams();
  const [state, setState] = useState({
    activeBt: "",
    apiData: [],
    loading: true,
    error: null,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items to display per page

  useEffect(() => {
    fetchData();
  }, [userid, state.activeBt]);

  // Connect to the Socket.IO server
  const socket = io(BaseUrl.BaseUrl, {
    transports: ["websocket"],
  });

  const fetchData = async () => {
    const API_URL = `${BaseUrl.BaseUrl}/active_booking/${userid}`;
    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      const filteredData =
        data?.message?.active_bookings?.filter(
          (booking) =>
            booking.status === "in Progress" || booking.status === "Completed"
        ) || [];

      setState((prevState) => ({
        ...prevState,
        apiData: filteredData,
        loading: false,
      }));
      autoMarkCompleted(filteredData);
      console.log("data1111", data.message.active_bookings);
    } catch (error) {
      console.error(
        "An error occurred while marking the booking complete",
        error
      );
    }
  };

  const autoMarkCompleted = (bookings) => {
    const currentDate = new Date();
    bookings.forEach((booking) => {
      const exitDate = new Date(booking.exit_date);
      if (exitDate < currentDate && booking.status !== "Completed") {
        handleMarkComplete(booking.booking_id);
      }
    });
  };

  const handleMarkComplete = async (booking_id) => {
    // Update UI state instantly
    setState((prevState) => ({
      ...prevState,
      apiData: prevState.apiData.map((item) =>
        item.booking_id === booking_id ? { ...item, status: "Completed" } : item
      ),
    }));

    // Emit the notification event without any data
    socket.emit("notification", {
      type: "booking",
      userId: "",
      property_name: "",
      withdrawal_type: "",
      booking_status: "Completed",
      name: "",
      read: false,
      createdAt: Date.now(),
    });
    console.log("Notification event emitted"); // Log to console

    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/booking_cancel_by_host
 `,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            booking_id: booking_id,
            newStatus: "Completed",
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error || "An error occurred");
      }
      console.log(data.message);
    } catch (error) {
      console.error(
        "An error occurred while marking the booking complete",
        error
      );

      // Revert the UI state if API call fails
      setState((prevState) => ({
        ...prevState,
        apiData: prevState.apiData.map((item) =>
          item.booking_id === booking_id
            ? { ...item, status: "in Progress" }
            : item
        ),
      }));
    }
  };

  const handleCancelBooking = async (booking_id, reason, closePopup) => {
  // Check if the reason is empty
  if (reason.trim() === "") {
    alert("You have to enter a reason for the cancellation.");
    return; // Stop execution if reason is not provided
  }

  // Update UI state instantly
  setState((prevState) => ({
    ...prevState,
    apiData: prevState.apiData.filter(
      (item) => item.booking_id !== booking_id
    ),
  }));
  closePopup();

  // Emit the notification event with the cancellation reason
  socket.emit("notification", {
    type: "booking",
    userId: "", // Specify the user ID if available
    property_name: "", // Include property name if available
    withdrawal_type: "",
    booking_status: "cancel by host",
    name: "", // Include host name if needed
    read: false,
    createdAt: Date.now(),
    reason, // Add the cancellation reason
  });

  try {
    const response = await fetch(
      `${BaseUrl.BaseUrl}/booking_cancel_by_host`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          booking_id,
          newStatus: "cancel by host",
          reason, // Add the cancellation reason
        }),
      }
    );

    const data = await response.json();

    if (data.error) {
      throw new Error(data.error || "An error occurred");
    }

    console.log(data.message); // Log success message
    console.log("Reason:", reason);
  } catch (error) {
    console.error("An error occurred while canceling the booking", error);

    // Re-add the booking back to the UI if API call fails
    fetchData(); // Re-fetch data to restore the original state
  }
};


  const handleRejectBooking = async (booking_id) => {
    setState((prevState) => ({
      ...prevState,
      apiData: prevState.apiData.map((item) =>
        item.booking_id === booking_id
          ? { ...item, status: "cancel by host" }
          : item
      ),
    }));

    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/booking_cancel_by_host
 `,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            booking_id: booking_id,
            newStatus: "cancel by host",
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error || "An error occurred");
      }
      console.log(data.message);
    } catch (error) {
      console.error("Error rejecting the booking", error);
      // Re-add the booking back to the UI if API call fails
      fetchData(); // Re-fetch data to restore original state
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(state.apiData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = state.apiData.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (state.loading) {
      return (
        <div className="booking-history">
          <SkeletonWrapper
          containerClass="Skeleton-host-booking-list"
          items={[
            { className: "skeleton-header" },
            { className: "skeleton-heading" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-page" },
          ]}
        />
        </div>
      );
    }

  if (state.error) {
    return (
      <div style={{ color: "red", textAlign: "center", paddingTop: 20 }}>
        Error: {state.error}
      </div>
    );
  }
  const parseExtraServices = (services) => {
    try {
      return (
        JSON.parse(services)
          ?.map((service) => service.item)
          .join(", ") || ""
      );
    } catch {
      return "";
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
        Active Booking
      </h3>
      <div className="topMain" style={{ width: "100%" }}>
        {currentData.length === 0 ? (
          <p style={{ textAlign: "center" }}>No bookings available</p>
        ) : (
          <div className="table-Page" style={{ overflowX: "auto" }}>
            <table
              className="table-full"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    SERVICE
                  </th>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    Entry Date & Exit Date
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Total
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Status
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "left",
                      }}
                    >
                      {item.nights} Night
                      {item.extra_services && (
                        <> + {parseExtraServices(item.extra_services)}</>
                      )}
                      <br />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          color: "#64748B",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "0px",
                          }}
                        >
                          {" "}
                          Booking by: {item.user_name}
                        </p>
                        <Popup
                          className="popupdoc"
                          trigger={
                            <button
                              style={{
                                background: "none",
                                color: "#F15A29",
                                fontWeight: "bold",
                              }}
                            >
                              View Profile
                            </button>
                          }
                          modal
                          contentStyle={{
                            width: "auto",
                            padding: "20px",
                            borderRadius: 10,
                            height: "auto",
                            zIndex: "99999",
                          }}
                        >
                          {(close) => (
                            <div style={{ textAlign: "right" }}>
                              <button
                                onClick={() => close()}
                                style={{
                                  borderRadius: "50%",
                                  background: "none",
                                  border: "none",
                                  width: 25,
                                  height: 25,
                                }}
                              >
                                <img
                                  style={{
                                    width: 15,
                                    height: 15,
                                    alignItems: "center",
                                  }}
                                  src={require("../../../assets/Icon.png")}
                                />
                              </button>
                              <div
                                id="userdetaip"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <h4>Uploaded Documents</h4>
                                {/* Displaying the user images */}
                                <div id="imgflex">
                                  {item.user_image &&
                                  Array.isArray(item.user_image) &&
                                  item.user_image.length > 0 ? (
                                    item.user_image.map((img, idx) => (
                                      <a
                                        key={idx}
                                        href={`${BaseUrl.BaseUrl}/${img}`}
                                        download={`user_image_${idx}`}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <img
                                          src={`${BaseUrl.BaseUrl}/${img}`}
                                          alt="User"
                                          style={{
                                            width: "200px",
                                            height: "150px",
                                            borderRadius: "10px",
                                            marginBottom: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </a>
                                    ))
                                  ) : item.user_image ? (
                                    <a
                                      href={`${BaseUrl.BaseUrl}/${item.user_image}`}
                                      download="user_image"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <img
                                        src={`${BaseUrl.BaseUrl}/${item.user_image}`}
                                        alt="User"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          borderRadius: "50%",
                                          marginBottom: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </a>
                                  ) : (
                                    <p>No image available</p>
                                  )}
                                </div>

                                <h4>User Details </h4>

                                <p>
                                  <strong>User Name:</strong> {item.user_name}
                                </p>
                                <p>
                                  <strong>Identity Type:</strong>{" "}
                                  {item.identity_type}
                                </p>
                                <p>
                                  <strong>Booking Date:</strong>{" "}
                                  {item.booking_date}
                                </p>
                                <p>
                                  <strong>Entry Date:</strong> {item.entry_date}{" "}
                                  | <strong>Exit Date:</strong> {item.exit_date}
                                </p>
                                <p>
                                  <strong>Country:</strong> {item.country}
                                </p>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.entry_date} to {item.exit_date}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      ${item.owner_earnings}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#D1FAE5",
                          color: "#064E3B",
                          border: "none",
                          borderRadius: 5,
                          fontWeight: "500",
                        }}
                      >
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </button>
                    </td>

                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          disabled={item.status === "Completed"} // Disable button if status is 'Completed'
                          style={{
                            backgroundColor: "#10B981",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: 5,
                            border: "none",
                            cursor:
                              item.status === "Completed"
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={() => handleMarkComplete(item.booking_id)}
                        >
                          ✓
                        </button>
                        <button
                          style={{
                            backgroundColor: "#EF4444",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: 5,
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRejectBooking(item.booking_id)}
                        >
                          ✖
                        </button>
                      </div>
                    </td>

                    <td
                      style={{
                        padding: 10,
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <Popup
                        shouldCloseOnOverlayClick={false}
                        closeOnDocumentClick={false}
                        trigger={
                          <a>
                            <img
                              style={{
                                width: 24,
                                height: 24,
                                cursor: "pointer",
                              }}
                              src={require("../../../assets/threeDot.png")}
                            />
                          </a>
                        }
                        position="bottom right"
                        contentStyle={{ maxHeight: "60px", overflow: "auto" }}
                      >
                        {(close) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              border: "1px solid #ddd",
                            }}
                           onMouseLeave={() => close()}
                          >
                            <Popup
                              shouldCloseOnOverlayClick={false}
                              closeOnDocumentClick={false}
                              trigger={
                                <button
                                  style={{
                                    color: "#F87171",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  Cancel Booking
                                </button>
                              }
                              modal
                              contentStyle={{
                                width: window.innerWidth <= 768 ? "80%" : "40%",
                                padding: "20px",
                                borderRadius: 10,
                                height: "auto",
                              }}
                            >
                              {(innerClose) => {
                                const [reason, setReason] = React.useState("");

                                const handleConfirmCancel = () => {
                                  handleCancelBooking(
                                    item.booking_id,
                                    reason,
                                    innerClose
                                  );
                                };

                                return (
                                  <div style={{ textAlign: "center" }}>
                                    <h5>
                                      Are you sure you want to cancel the
                                      booking?
                                    </h5>
                                    <div
                                      style={{
                                        marginTop: 10,
                                        fontSize: 14,
                                        color: "#555",
                                        textAlign: "left",
                                      }}
                                    >
                                      <p>
                                        <strong>
                                          Host Cancellation Policies
                                        </strong>
                                      </p>
                                      <ul>
                                        <li>Cancellation Notice Period:</li>
                                        <ul>
                                          <li>
                                            More than 36 hours notice: No
                                            penalty.
                                          </li>
                                          <li>
                                            Less than 36 hours notice: Penalties
                                            apply.
                                          </li>
                                        </ul>
                                        <li>
                                          Penalties for Late Cancellations:
                                        </li>
                                        <ul>
                                          <li>
                                            Penalty Fee: $30 for cancellations
                                            made with less than 36 hours notice.
                                          </li>
                                          <li>
                                            Service Fee Forfeiture: The service
                                            fee for the canceled booking is
                                            non-refundable.
                                          </li>
                                          <li>
                                            Guest Compensation and Support:
                                          </li>
                                          <ul>
                                            <li>
                                              Guest Compensation: The $30
                                              penalty fee will compensate the
                                              guest and Caribbeaneaze for the
                                              inconvenience.
                                            </li>
                                            <li>
                                              Rebooking Assistance: Guests will
                                              receive help finding alternative
                                              accommodations on our platform.
                                            </li>
                                          </ul>
                                          <li>
                                            Review Impact: Guests can leave a
                                            review about the cancellation on the
                                            host’s profile.
                                          </li>
                                          <li>
                                            Account Suspension: Multiple late
                                            cancellations may lead to temporary
                                            suspension or removal of the host’s
                                            account.
                                          </li>
                                        </ul>
                                      </ul>
                                    </div>

                                    <textarea
                                      required // Ensures the field is mandatory
                                      placeholder="Enter reason for cancellation"
                                      value={reason}
                                      onChange={(e) =>
                                        setReason(e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        height: "80px",
                                        marginTop: "15px",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ddd",
                                      }}
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "10px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <button
                                        style={{
                                          backgroundColor: "#EF4444",
                                          color: "white",
                                          padding: "5px 15px",
                                          borderRadius: "5px",
                                          border: "none",
                                          cursor: "pointer",
                                          width: "20%",
                                          transition:
                                            "background-color 0.3s ease",
                                        }}
                                        onMouseEnter={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#DC2626")
                                        }
                                        onMouseLeave={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#EF4444")
                                        }
                                        onClick={handleConfirmCancel}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        style={{
                                          backgroundColor: "#6B7280",
                                          color: "white",
                                          padding: "5px 15px",
                                          borderRadius: "5px",
                                          border: "none",
                                          cursor: "pointer",
                                          width: "20%",
                                          transition:
                                            "background-color 0.3s ease",
                                        }}
                                        onMouseEnter={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#4B5563")
                                        }
                                        onMouseLeave={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#6B7280")
                                        }
                                        onClick={innerClose}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                );
                              }}
                            </Popup>
                          </div>
                        )}
                      </Popup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination Controls */}
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="previbutton"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent2.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: "none",
                cursor: "pointer",
              }}
            />
          </button>
          <span style={{ alignContent: "center" }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="previbutton"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: 30,
                cursor: "pointer",
              }}
            />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActiveBookingList;
