import React, { Component } from 'react';
import PropertysideBar from './PropertysideBar';
import RegistrationHeader from '../RegistrationHeader';
import ListAddProperty from './ListAddProperty';
// import Propertyimages from './Propertyimage';
import AddExtraService from './AddExtraService';
import Located from './PropertyLoacation';
import PropertyImages from './Propertyimage';
 
export default class AddProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowComponentId: { navigationTo: 'per_Info', id: 1 },
      selectedIds: [1],
    };
  }
 
  handleSelectionMultiple = (id) => {
    let selectedIds = [...this.state.selectedIds]; // clone state
 
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((_id) => _id !== id);
    } else {
      selectedIds.push(id);
    }
    this.setState({ selectedIds });
  };
 
  render() {
    const { ShowComponentId, selectedIds } = this.state;
 
    return (
      <div>
        <RegistrationHeader />
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', padding: 5 }}>
            <div style={{ width: '30%',padding: 0 ,
            width: window.innerWidth <= 600 ? '10%' : '',
            }}>
              <PropertysideBar Highlight={selectedIds} />
            </div>
 
            <div style={{ width: '75%' }}>
              {ShowComponentId.id === 1 && (
                <ListAddProperty
                  NextCallBack={(value) =>
                    this.setState({ ShowComponentId: value }, () => {
                      if (value.bt_type === 'Back') {
                        this.handleSelectionMultiple(value.currentid);
                      } else {
                        this.handleSelectionMultiple(value.id);
                      }
                    })
                  }
                />
              )}
 
              {ShowComponentId.id === 2 && (
                <Located
                  NextCallBack={(value) =>
                    this.setState({ ShowComponentId: value }, () => {
                      if (value.bt_type === 'Back') {
                        this.handleSelectionMultiple(value.currentid);
                      } else {
                        this.handleSelectionMultiple(value.id);
                      }
                    })
                  }
                />
              )}
 
              {ShowComponentId.id === 3 && (
                <PropertyImages
                  NextCallBack={(value) =>
                    this.setState({ ShowComponentId: value }, () => {
                      if (value.bt_type === 'Back') {
                        this.handleSelectionMultiple(value.currentid);
                      } else {
                        this.handleSelectionMultiple(value.id);
                      }
                    })
                  }
                />
              )}
 
              {ShowComponentId.id === 4 && (
                <AddExtraService
                  NextCallBack={(value) =>
                    this.setState({ ShowComponentId: value }, () => {
                      if (value.bt_type === 'Back') {
                        this.handleSelectionMultiple(value.currentid);
                      } else {
                        this.handleSelectionMultiple(value.id);
                      }
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
 