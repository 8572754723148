import React, { useState, useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
 
const Located = ({ NextCallBack }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [countryRegion, setCountryRegion] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
 
 
 
  useEffect(() => {
    const savedData = localStorage.getItem('property_Data');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setCountryRegion(parsedData.countryRegion || '');
      setState(parsedData.state || '');
      setCity(parsedData.city || '');
      setStreetAddress(parsedData.streetAddress || '');
      setStates(State.getStatesOfCountry(parsedData.countryRegion || ''));
      setCities(City.getCitiesOfState(parsedData.countryRegion || '', parsedData.state || ''));
    }
  }, []);
 
 
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
 
        const apiKey = 'AIzaSyAPGUoanvIaJoN2G2asrYqts3_xX3HCKXQ';
        const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${latitude},${longitude}`;
 
        try {
          const response = await fetch(url);
          const data = await response.json();
 
          if (data.status === 'OK') {
            const addressComponents = data.results[0].address_components;
 
            const country = addressComponents.find((component) =>
              component.types.includes('country')
            )?.short_name;
 
            const stateName = addressComponents.find((component) =>
              component.types.includes('administrative_area_level_1')
            )?.short_name;
 
            const cityName = addressComponents.find((component) =>
              component.types.includes('locality')
            )?.long_name;
 
            const formattedAddress = data.results[0].formatted_address;
 
            setCurrentLocation({ latitude, longitude });
            setCountryRegion(country || '');
            setState(stateName || '');
            setCity(cityName || '');
            setStreetAddress(formattedAddress || '');
            setUseCurrentLocation(true);
 
            if (country) {
              const statesList = State.getStatesOfCountry(country);
              setStates(statesList);
 
              if (stateName) {
                const citiesList = City.getCitiesOfState(country, stateName);
                setCities(citiesList);
              }
            }
          } else {
            console.error('Geocoding request failed:', data.status);
          }
        } catch (error) {
          console.error('Error getting geolocation data:', error);
        }
      }, (error) => {
        console.error('Error getting current location:', error);
      });
    } else {
      console.error('Geolocation is not supported by your browser.');
    }
  };
 
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountryRegion(selectedCountry);
    setState('');
    setCity('');
    setStates(State.getStatesOfCountry(selectedCountry));
    setCities([]);
  };
 
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity('');
    setCities(City.getCitiesOfState(countryRegion, selectedState));
  };
 
  const handleSaveDataToLocalStorage = () => {
    if (!streetAddress) {
      alert('Street Address is required!');
      return;
    }
 
    const dataToSave = {
      currentLocation,
      countryRegion,
      state,
      city,
      streetAddress,
    };
 
    localStorage.setItem('property_Data', JSON.stringify(dataToSave));
    NextCallBack({ navigationTo: 'PropertyImages', id: 3, bt_type: 'Next' });
    console.log('Data saved to localStorage:', dataToSave);
  };
 
 
  return (
    <div className='main-section' style={{ width: '90%', padding: 20, borderLeft: '1px solid #94A3B8' }}>
      <label style={{ fontSize: 17, fontWeight: '500', marginLeft: 20 }}>Step 2/4</label>
      <h4 style={{ fontSize: 25, fontWeight: '700', marginLeft: 20 }}>Where are you Located</h4>
      <label style={{ fontSize: 16, fontWeight: '400', color: '#0F172A', marginLeft: 20 }}>
        Please Complete these Location info of your property
      </label>
      <div style={{ width: '90%', justifyContent: 'center', marginLeft: 20, height: 500 }}>
        <div
          className="opt-Located"
          style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 30 }}
        >
          <div className='min-opt' style={{ marginTop: 6, width: '30%' }}>
            <label>Select Country/Region</label>
            <select
              id="countryRegion"
              style={{
                width: '98%',
                height: '2.6rem',
                borderColor: '#E2E8F0',
                borderRadius: 7,
                background: 'none',
              }}
              value={countryRegion}
              onChange={handleCountryChange}
            >
              <option value="">Select Country</option>
              {Country.getAllCountries().map((country) => (
                <option key={country.isoCode} value={country.isoCode}>{country.name}</option>
              ))}
            </select>
          </div>
          <div className='type-p' style={{ marginTop: 15, width: '30%' }}>
            <label>State</label>
            <select
              id="state"
              style={{
                width: '98%',
                height: '2.2rem',
                borderColor: '#E2E8F0',
                borderRadius: 7,
                background: 'none',
              }}
              value={state}
              onChange={handleStateChange}
              disabled={!countryRegion}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state.isoCode} value={state.isoCode}>{state.name}</option>
              ))}
            </select>
          </div>
          <div className='type-p' style={{ marginTop: 15, width: '30%' }}>
            <label>City</label>
            <select
              id="city"
              style={{
                width: '100%',
                height: '2.2rem',
                borderColor: '#E2E8F0',
                borderRadius: 7,
                background: 'none',
              }}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              disabled={!state}
            >
              <option value="">Select City</option>
              {cities.map((city) => (
                <option key={city.name} value={city.name}>{city.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ width: '100%', marginTop: 25 }}>
          <label style={{ color: '#474554', fontSize: 17 }}>Street Address</label>
          <br />
          <input
            style={{
              width: '100%',
              height: 45,
              borderWidth: 0.1,
              marginTop: 5,
              borderColor: '#E2E8F0',
              borderRadius: 7,
            }}
            placeholder="Enter Your Street Address"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          />
        </div>
        <button
          style={{
            color: 'white',
            height: 45,
            width: '100%',
            marginTop: 25,
            borderRadius: 10,
            border: 'none',
            background: 'linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)',
          }}
          onClick={handleUseCurrentLocation}
        >
          Use current Location
        </button>
      </div>
      <div
        style={{ display: 'flex', marginTop: 30, width: '92%', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <button
          className='back-btn'
          style={{
            width: '10%',
            borderRadius: 10,
            height: '45px',
            borderWidth: 0,
            background: 'white',
            color: 'black',
          }}
          onClick={() =>
            NextCallBack({ navigationTo: 'ListAddProperty', id: 1, currentid: 2, bt_type: 'Back' })
          }
        >
          Back
        </button>
        <button
          className='next-btn'
          style={{
            color: 'white',
            background: '#F15A29',
            width: '10%',
            borderRadius: 10,
            height: '45px',
            marginLeft: 10,
            border:'none'
          }}
          onClick={handleSaveDataToLocalStorage}
        >
          Next
        </button>
      </div>
    </div>
  );
};
 
export default Located;
 
 