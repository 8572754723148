import React from "react";
import "./SkeletonHome.css";
 
const SkeletonHome = () => {
  return (
    <>
      <div class="skeleton-parent">
        <div class="skeleton-circle-row">
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
          <div class="skeleton-circle"></div>
        </div>
        <div class="skeleton-rectangle-box"></div>
      </div>
      <div class="skeleton-second-parent">
        <div class="skeleton-row1">
            <div class="skeleton-box1"></div>
            <div class="skeleton-box1"></div>
            <div class="skeleton-box1"></div>
            <div class="skeleton-box1"></div>
        </div>
        <div class="skeleton-row2">
            <div class="skeleton-box2"></div>
            <div class="skeleton-box2"></div>
            <div class="skeleton-box2"></div>
        </div>
        <div class="skeleton-rectangle-box2"></div>
    </div>
    </>
  );
};
 
export default SkeletonHome;
 
 