import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Loader from "../../../assets/loader.gif";
import BaseUrl from "../../Server/BaseUrl";
import "./ActiveBookingUserList.css";
import { io } from "socket.io-client";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

const ActiveBookingUserList = ({ propertyData }) => {
  const [cancellationReason, setCancellationReason] = useState("");
  const { userid } = useParams();
  const [state, setState] = useState({
    activeBt: "",
    apiData: [],
    loading: true,
    error: null,
    selectedProfileData: null, // Add this to store profile data
    isProfileLoading: false, // Add to handle profile loading state
    isProfilePopupOpen: false,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items to display per page

  useEffect(() => {
    fetchData();
  }, [userid, state.activeBt]);
  // console.log("user id ", userid);

  const fetchData = async () => {
    // const API_URL = `https://www.sher9522.caribbeaneaze.com/active_booking_user/${userid}`;
    const API_URL = `${BaseUrl.BaseUrl}/active_booking_user/${userid}`;

    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Filter the data to only include bookings with status 'in progress'
      const filteredData =
        data?.message?.active_bookings?.filter(
          (booking) =>
            booking.status === "in Progress" || booking.status === "Completed"
        ) || [];

      setState((prevState) => ({
        ...prevState,
        apiData: filteredData,
        loading: false,
      }));
      console.log("data1111", data.message.active_bookings);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.message,
        loading: false,
      }));
    }
  };

  if (state.loading) {
    return (
      <div className="booking-history">
        <SkeletonWrapper
          containerClass="Skeleton-host-booking-list"
          items={[
            { className: "skeleton-header" },
            { className: "skeleton-heading" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-content" },
            { className: "skeleton-page" },
          ]}
        />
      </div>
    );
  }

  const handleCancelBooking = async (booking_id, reason, closePopup) => {
    if (reason.trim() === "") {
      alert("You have to enter a reason for the cancellation.");
      return; // Stop execution if reason is not provided
    }
    console.log("clicked");
    // Connect to the Socket.IO server
    const socket = io(BaseUrl.BaseUrl, {
      transports: ["websocket"],
    });

    // Emit the notification event without any data
    socket.emit("notification", {
      type: "booking",
      userId: "",
      property_name: "",
      withdrawal_type: "",
      booking_status: "cancel by user",
      name: "",
      read: false,
      createdAt: Date.now(),
      reason,
    });
    console.log("Notification event emitted"); // Log to console

    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/booking_cancel_by_user
 `,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            booking_id: booking_id,
            newStatus: "cancel by user",
            reason, // Add the cancellation reason
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        console.error(data.error || "An error occurred");
      } else {
        console.log(data.message);
        // Update the UI after cancellation
        setState((prevState) => ({
          ...prevState,
          apiData: prevState.apiData.map((item) =>
            item.booking_id === booking_id
              ? { ...item, status: "cancel by host" }
              : item
          ),
        }));
        closePopup();
      }
    } catch (error) {
      console.error("An error occurred while processing the request", error);
    }
  };

  // console.log(
  //   "user view profile11111======>",
  //   state?.selectedProfileData?.property_details
  // );

  // Calculate paginated data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.apiData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(state.apiData.length / itemsPerPage);

  console.log("current Data: ", currentItems);
  // console.log("user view profile======>", currentItems);

  // Function to fetch profile data when "View Profile" is clicked
  const handleViewProfile = (property_id, booking_id) => {
    const selectedItem = currentItems.find(
      (item) =>
        item.property_id === property_id && item.booking_id === booking_id
    );

    if (selectedItem) {
      setState((prevState) => ({
        ...prevState,
        selectedProfileData: selectedItem,
        isProfilePopupOpen: true,
      }));
    }
  };

  const handleCloseProfilePopup = () => {
    setState((prevState) => ({
      ...prevState,
      isProfilePopupOpen: false, // Close the profile popup
    }));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (state.error) {
    return (
      <div style={{ color: "red", textAlign: "center", paddingTop: 20 }}>
        Error: {state.error}
      </div>
    );
  }
  // const sanitizedProfileUrl = state?.selectedProfileData?.owner_image?.replace(/^http:\/\/localhost:4000/, '')
  const sanitizedProfileUrl = state?.selectedProfileData?.owner_image;

  const getDetailedCancellationPolicy = (policy) => {
    switch (policy) {
      case "Flexible":
        return "Flexible:-  Allows guests to receive a full refund if they cancel at least 24 hours before check-in (local time). Hosts will also forfeit the cleaning fee. If a guest cancels less than 24 hours before check-in, they will still be charged for the first night but are entitled to a refund for the remaining nights. If a guest cancels their reservation after checking in, they may be eligible for a partial refund for the remaining nights of the reservation.";
      case "Moderate":
        return "Moderate: This policy allows fewer cancellations. Guests must cancel the reservation at least 5 days before the reservation date to receive a full refund of the accommodation fees. If the guest cancels within 5 days of the reservation start date, the first night and the service fee is non-refundable. They also only get 50% of the booking fees back. If the customer decides to cancel the reservation after check-in, 50% of the remaining nightly accommodation fees will be refunded. However, they still pay for nights spent.";
      case "Firm":
        return "Firm: Guests must cancel at least 30 days prior to check-in to receive a full refund. This policy allows for a 50% refund if your guests cancel between 7 and 30 days prior to check-in.If a guest cancels less than seven days prior to check-in, the host will still receive 100% of everything (nights booked). This is a good middle ground between strict and flexible cancellation policies.In addition, guests can receive a full refund if they cancel within 48 hours of the booking date, as long as they cancel at least 14 days before check-in.";
      case "Strict":
        return "Strict: Guests receive a full refund if they cancel within 48 hours of booking and at least 14 days before the property`s local check-in time. After 48 hours, guests are only entitled to a 50% refund, regardless of how far in advance the check-in date is.Guests will also receive a 50% refund of accommodation fees if they cancel 7-14 days before the check-in date. They also get the cleaning fee back, but not the service fee. If the customer cancels the reservation less than 7 days in advance, he is not entitled to a refund.";
      default:
        return "No detailed policy available.";
    }
  };

  const parseExtraServices = (services) => {
    try {
      return (
        JSON.parse(services)
          ?.map((service) => service.item)
          .join(", ") || ""
      );
    } catch {
      return "";
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
        Active User Booking
      </h3>
      <div className="topMain" style={{ width: "100%" }}>
        {currentItems.length === 0 ? (
          <p style={{ textAlign: "center" }}>No bookings available</p>
        ) : (
          <div className="table-Page" style={{ overflowX: "auto" }}>
            <table
              className="table-full"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    SERVICE
                  </th>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    Entry Date & Exit Date
                  </th>
                  <th
                    style={{
                      padding: 10,
                      borderBottom: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    Booking Date and time
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Total
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Status
                  </th>
                  <th style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "left" }}>
                      {item.nights} Night
                      {item.extra_services && (
                        <> + {parseExtraServices(item.extra_services)}</>
                      )}
                      <br />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          color: "#64748B",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "0px",
                          }}
                        >
                          {" "}
                          Service by:{" "}
                          {item.owner_name === "User"
                            ? item.user_name
                            : item.owner_name}
                          .
                        </p>

                        <button
                          style={{
                            background: "none",
                            color: "#F15A29",
                            fontWeight: "bold",
                          }}
                          onClick={() =>
                            handleViewProfile(item.property_id, item.booking_id)
                          }
                        >
                          View Profile
                        </button>
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.entry_date} to {item.exit_date}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.booking_date}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      ${item.total_amount}
                    </td>
                    <td
                      style={{
                        padding: "20px 10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          border: "none",
                          borderRadius: 5,
                        }}
                      >
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </button>
                    </td>
                    {/* <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <button style={{ padding: '5px 10px', backgroundColor: '#2196F3', color: 'white', border: 'none', borderRadius: 5 }}>
                                                Mark Complete
                                            </button>
                                        </td> */}
                    <td
                      style={{
                        padding: 10,
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <Popup
                        shouldCloseOnOverlayClick={false}
                        closeOnDocumentClick={false}
                        trigger={
                          <a>
                            <img
                              style={{
                                width: 24,
                                height: 24,
                                cursor: "pointer",
                              }}
                              src={require("../../../assets/threeDot.png")}
                            />
                          </a>
                        }
                        position="bottom right"
                        contentStyle={{ maxHeight: "100px", overflow: "auto" }}
                      >
                        {(close) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              border: "none",
                            }}
                            // onMouseLeave={() => close()
                          >
                            <Popup
                              shouldCloseOnOverlayClick={false}
                              closeOnDocumentClick={false}
                              trigger={
                                <button
                                  style={{
                                    color: "#F87171",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  Cancel Booking
                                </button>
                              }
                              modal
                              contentStyle={{
                                width: "90%",
                                maxWidth: "400px",
                                padding: "20px",
                                borderRadius: 10,
                              }}
                            >
                              {(innerClose) => {
                                const [reason, setReason] = useState("");

                                const handleCancelWithReason = () => {
                                  handleCancelBooking(
                                    item.booking_id,
                                    reason,
                                    innerClose
                                  );
                                };

                                return (
                                  <div style={{ textAlign: "center" }}>
                                    <h5>
                                      Are you sure you want to cancel the
                                      booking?
                                    </h5>
                                    <div id="policy-info-text">
                                      {getDetailedCancellationPolicy(
                                        item?.cancellationPolicy
                                      )}
                                    </div>
                                    <textarea
                                      placeholder="Enter the reason for cancellation"
                                      value={reason}
                                      onChange={(e) =>
                                        setReason(e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        height: "80px",
                                        marginTop: "10px",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        resize: "none",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "10px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <button
                                        style={{
                                          backgroundColor: "#EF4444",
                                          color: "white",
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          border: "none",
                                          cursor: "pointer",
                                          transition:
                                            "background-color 0.3s ease",
                                          width: "40%",
                                        }}
                                        onMouseEnter={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#DC2626")
                                        }
                                        onMouseLeave={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#EF4444")
                                        }
                                        onClick={handleCancelWithReason}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        style={{
                                          backgroundColor: "#10B981",
                                          color: "white",
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          border: "none",
                                          width: "40%",
                                          cursor: "pointer",
                                          transition:
                                            "background-color 0.3s ease",
                                        }}
                                        onMouseEnter={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#059669")
                                        }
                                        onMouseLeave={(e) =>
                                          (e.target.style.backgroundColor =
                                            "#10B981")
                                        }
                                        onClick={() => innerClose()}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                );
                              }}
                            </Popup>
                          </div>
                        )}
                      </Popup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination Controls */}
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="previbutton"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent2.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: "none",
                cursor: "pointer",
              }}
            />
          </button>
          <span style={{ alignContent: "center" }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="previbutton"
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            <img
              src={require("../../../assets/Untitled_designtrasprent.png")}
              style={{
                background: "none",
                height: 50,
                width: 50,
                borderRadius: 30,
                cursor: "pointer",
              }}
            />{" "}
          </button>
        </div>

        {/* Profile Dialog */}
        <Dialog
          open={state.isProfilePopupOpen}
          onClose={handleCloseProfilePopup}
          PaperProps={{
            style: {
              width: "90%",
              maxWidth: "400px",
              background: "white",
              borderRadius: "60px",
            },
          }}
        >
          <DialogTitle>Property Details </DialogTitle>
          <DialogContent>
            {state.isProfileLoading ? (
              <div style={{ textAlign: "center", padding: 20 }}>
                <img
                  src={Loader}
                  alt="Loading..."
                  style={{ width: 50, height: 50 }}
                />
              </div>
            ) : state.selectedProfileData ? (
              <div className="image-container">
                <img
                  src={`${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`}
                  alt=""
                  className="profile-image"
                />
                <p style={{ marginTop: "10px" }}>
                  <strong>Owner Name:</strong>{" "}
                  {state.selectedProfileData.owner_name}
                </p>
                <p style={{ marginTop: "10px" }}>
                  <strong>Property Name:</strong>{" "}
                  {state?.selectedProfileData?.property_details?.property_name}
                </p>
                <p style={{ marginTop: "10px" }}>
                  <strong>Country:</strong>{" "}
                  {state?.selectedProfileData?.property_details?.country}
                </p>
                <p style={{ marginTop: "10px" }}>
                  <strong>Location:</strong>{" "}
                  {state?.selectedProfileData?.property_details?.street_address}
                </p>

                {/* Add more details as needed */}
              </div>
            ) : (
              <p>No profile data available.</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseProfilePopup}
              style={{
                backgroundColor: "#F87171",
                color: "white",
                borderRadius: "5px",
                padding: "10px 20px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                width: "20%",
                margin: "0 auto",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#E63946")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#F87171")}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ActiveBookingUserList;
