import React, { useState, useEffect } from "react";
import "./sidebar.css";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import BaseUrl from "../Server/BaseUrl";
import { io } from "socket.io-client";
import SkeletonWrapper from "../HomeComonent/Skeleton/SkeletonWrapper";
 
function Sidebar() {
  const [balance, setBalance] = useState(0);
  const [availableJobs, setAvailableJobs] = useState(0);
  const [earnedThisMonth, setEarnedThisMonth] = useState(0);
  const [expectedEarnings, setExpectedEarnings] = useState(0);
  const [isPaypalPopupVisible, setIsPaypalPopupVisible] = useState(false);
  const [isUserPlusPopupVisible, setIsUserPlusPopupVisible] = useState(false);
  const [paypalId, setPaypalId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [payoutMethod, setPayoutMethod] = useState("");
  const [showPayoutMethodFields, setShowPayoutMethodFields] = useState(true); // State for controlling visibility
  const [withdrawalAmount, setWithdrawalAmount] = useState(""); // New field for withdrawal amount
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [paypalWithdrawals, setPaypalWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [showIcons, setShowIcons] = useState(true);
  const [stopFetching, setStopFetching] = useState(false);
 
  // const [balance, setBalance] = useState(0);
 
  // Connect to the Socket.IO server
  const socket = io(BaseUrl.BaseUrl, {
    transports: ["websocket"],
  });
 
  const { userid } = useParams();
  useEffect(() => {
    setLoading(true);
    fetch(BaseUrl.BaseUrl + `/owner/deshboard/${userid}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.message);
        setBalance(data.message?.completed_job_amount || 0);
      })
      .catch((err) => {
        setError("Error fetching data", err);
      })
      .finally(()=> setLoading(false));
  }, [userid]);
 
  // console.log('api data', userData)
  useEffect(() => {
    if (!stopFetching) {
      fetchPaypalWithdrawals();
 
      const intervalId = setInterval(() => {
        if (!stopFetching) fetchPaypalWithdrawals();
      }, 5000);
 
      return () => clearInterval(intervalId);
    }
  }, [userid, stopFetching]);
 
  // Fetch PayPal withdrawal data
  const fetchPaypalWithdrawals = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BaseUrl.BaseUrl}/api/bank-paypal/${userid}`
      );
      const data = await response.json();
 
      if (Array.isArray(data.data) && data.data.length > 0) {
        setPaypalWithdrawals(data.data); // Update data
        const inProgressPayment = data.data.find(
          (item) => item.status === "In Progress"
        );
        const paidPayment = data.data.find((item) => item.status === "Paid");
        const rejectedPayment = data.data.find(
          (item) => item.status === "Rejected"
        );
        if (inProgressPayment) {
          setShowIcons(false);
          setStatusMessage("Your payment request is under process.");
        } else if (paidPayment) {
          setShowIcons(true);
          setStatusMessage("Your payment is done!");
          setStopFetching(true);
        } else if (rejectedPayment) {
          setShowIcons(true);
          setStatusMessage("Your payment request was rejected.");
          setStopFetching(true);
        } else {
          setShowIcons(true);
          setStatusMessage(""); // Empty message if no status found
        }
      } else {
        setErrorMessage("No Data Available");
      }
    } catch (err) {
      setErrorMessage("Error fetching PayPal withdrawal data");
    } finally {
      setLoading(false);
    }
  };
 
  // Effect for calculating balance after fetching PayPal withdrawals
  useEffect(() => {
    if (paypalWithdrawals.length > 0) {
      const paidWithdrawals = paypalWithdrawals.filter(
        (item) => item.status === "Paid"
      );
      const totalWithdrawn = paidWithdrawals.reduce(
        (sum, item) => sum + item.withdrawalAmount,
        0
      );
      const totalBalance = userData?.completed_job_amount - totalWithdrawn || 0;
      setBalance(totalBalance);
    }
  }, [paypalWithdrawals, userData]);
  // console.log("api data", balance);
 
  const handlePaypalIconClick = () => {
    setIsPaypalPopupVisible(true);
    setIsUserPlusPopupVisible(false); // Hide User Plus popup
  };
 
  const handleUserPlusIconClick = () => {
    setIsUserPlusPopupVisible(true);
    setIsPaypalPopupVisible(false); // Hide PayPal popup
  };
 
  const handleClosePaypalPopup = () => {
    setIsPaypalPopupVisible(false);
    setPaypalId("");
    setWithdrawalAmount("");
    setSuccessMessage("");
  };
 
  const handleCloseUserPlusPopup = () => {
    setIsUserPlusPopupVisible(false);
    setPayoutMethod("");
    setWithdrawalAmount("");
    setSuccessMessage("");
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if withdrawal amount exceeds balance
    if (parseFloat(withdrawalAmount) > balance) {
      setErrorMessage("Withdrawal amount cannot exceed personal balance.");
      return;
    }
 
    setPaypalId("");
    setWithdrawalAmount("");
 
    // Emit the notification event without any data
    socket.emit("notification", {
      type: "withdrawal",
      userId: "",
      property_name: "",
      withdrawal_type: "paypal",
      booking_status: "",
      name: "",
      read: false,
      createdAt: Date.now(),
    });
    // console.log("Notification event emitted"); // Log to console
 
    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/api/paypal-withdrawals/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paypalId,
            withdrawalAmount,
            user_id: userid,
          }),
        }
      );
 
      const data = response.json();
 
      if (!response.ok) {
        throw new Error("Failed to submit withdrawal request.");
      }
 
      // const result = await response.json();
      setSuccessMessage("Withdrawal request submitted successfully.");
      // console.log("Response:", result);
    } catch (error) {
      // console.log("Error submitting withdrawal request:", error);
      setSuccessMessage("Failed to submit withdrawal request.");
    }
 
    setTimeout(() => {
      setIsPaypalPopupVisible(false);
      setSuccessMessage("");
    }, 2000);
  };
 
  const handleSubmit1 = async (e) => {
    e.preventDefault();
 
    // Check if withdrawal amount exceeds balance
    if (parseFloat(withdrawalAmount) > balance) {
      setErrorMessage("Withdrawal amount cannot exceed personal balance.");
      return;
    }
 
    // Gather all form inputs
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
 
    // Adjust the structure for Western Union payout
    if (data.payoutMethod === "westernUnion") {
      data.westernUnionDetails = {
        recipientName: data.recipientName,
        recipientAddress: data.recipientAddress,
        country: data.country,
        wuphone: data.wuphone,
      };
 
      data.bankTransferDetails = null;
    } else if (payoutMethod === "bankTransfer") {
      data.bankTransferDetails = {
        bankName: data.bankName,
        bankAddress: data.bankAddress,
        bankCountry: data.bankCountry,
        accountNumber: data.accountNumber,
        accountType: data.accountType,
        swiftCode: data.swiftCode,
        iban: data.iban,
        routingNumber: data.routingNumber,
      };
      data.westernUnionDetails = null; // Ensure westernUnionDetails is null
    }
 
    // Add extra fields if needed
    data.user_id = userid;
    data.withdrawalAmount = withdrawalAmount;
 
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/payouts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
 
      if (!response.ok) {
        throw new Error("Failed to submit the form.");
      }
 
      // const result = await response.json();
      setSuccessMessage("Withdrawal request submitted successfully.");
      // console.log("Response:", result);
    } catch (error) {
      // console.log("Error submitting the form:", error);
      setSuccessMessage("Failed to submit withdrawal request.");
    }
 
    setTimeout(() => {
      setIsUserPlusPopupVisible(false);
      setSuccessMessage("");
    }, 2000);
  };
 
  const handlePayoutMethodChange = (e) => {
    setPayoutMethod(e.target.value);
    setShowPayoutMethodFields(false);
  };
 
  const handleWithdrawalAmountChange = (e) => {
    const amount = e.target.value;
    setWithdrawalAmount(amount);
 
    if (parseFloat(amount) > balance) {
      setErrorMessage("Withdrawal amount cannot exceed personal balance.");
    } else {
      setErrorMessage("");
    }
  };

  if (loading) {
    return (
      <div
        className="sidebar"
        id="sidebar"
        style={{ borderRight: "1px solid #ddd", paddingRight: "5px" }}
      >
        <SkeletonWrapper
          containerClass="Skeleton-earning-screen"
          items={[
            { className: "skeleton-header" },
            { className: "skeleton-content" },
            { className: "skeleton-header" },
            { className: "skeleton-content" },
            { className: "skeleton-header" },
            { className: "skeleton-content" },
          ]}
        />
      </div>
    );
  }
  return (
    <div className="sidebar" id="sidebar">
      <div className="earningcontent">
        <h2>Earning</h2>
 
        <div className="balance">
          <p>Personal Balance</p>
          <h1>${balance}</h1>
          <p>Available</p>
          <h4>
            {userData?.activeJob} Jobs (${userData?.active_job_amount})
          </h4>
          <p>Earned this Month</p>
          <h4>${userData?.current_month_earning}</h4>
          {statusMessage && (
            <p style={{ color: stopFetching ? "red" : "green" }}>
              {statusMessage}
            </p>
          )}
        </div>
 
        <div className="withdraw-method">
          <h2 style={{ whiteSpace: "nowrap" }}>Withdraw Method</h2>
 
          <div className="methods">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faPaypal}
              size="2x"
              onClick={handlePaypalIconClick}
            />
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faUserPlus}
              size="2x"
              onClick={handleUserPlusIconClick}
            />
          </div>
        </div>
      </div>
 
      {/* PayPal Popup */}
      {isPaypalPopupVisible && (
        <div className="popup-overlay786">
          <div className="popup-content786">
            <h2>Enter Your PayPal ID and Withdrawal Amount</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={paypalId}
                onChange={(e) => setPaypalId(e.target.value)}
                placeholder="PayPal ID"
                required
              />
              <input
                type="number"
                value={withdrawalAmount}
                // onChange={(e) => setWithdrawalAmount(e.target.value)}
                onChange={handleWithdrawalAmountChange}
                placeholder="Withdrawal Amount"
                required
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <div className="ff">
                <button
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "1px solid black",
                    fontSize: "12px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "1px solid black",
                    fontSize: "12px",
                  }}
                  type="button"
                  onClick={handleClosePaypalPopup}
                >
                  Close
                </button>
              </div>
            </form>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </div>
        </div>
      )}
 
      {/* User Plus Popup */}
      {isUserPlusPopupVisible && (
        <div className="popup-overlaybank786">
          <div className="popup-contentbank786">
            <h2>Host Banking and Payout Details Form</h2>
            <form onSubmit={handleSubmit1}>
              <div className="withdrorow">
                <div className="withdrocolumn">
                  <label htmlFor="fullName">
                    Full Name (as it appears on your bank account):
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Full Name:"
                    required
                    onChange={handlePayoutMethodChange}
                  />
                </div>
                <div className="withdrocolumn">
                  <label htmlFor="email">Email Address:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address:"
                    required
                    onChange={handlePayoutMethodChange}
                  />
                </div>
              </div>
              <div className="withdrorow">
                <div className="withdrocolumn">
                  <label htmlFor="phone">Phone Number:</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number:"
                    required
                    onChange={handlePayoutMethodChange}
                  />
                </div>
                <div className="withdrocolumn">
                  <label htmlFor="payoutMethod">Preferred Payout Method:</label>
                  <select
                    id="payoutMethod"
                    name="payoutMethod"
                    value={payoutMethod}
                    onChange={handlePayoutMethodChange}
                    required
                  >
                    <option value="">Select...</option>
                    <option value="bankTransfer">Bank Transfer</option>
                    <option value="westernUnion">Western Union</option>
                  </select>
                </div>
              </div>
              {/* Conditional rendering based on payout method */}
              {payoutMethod === "bankTransfer" && (
                <div id="bankTransferFields">
                  <div className="withdrorow">
                    <div className="withdrocolumn">
                      <label htmlFor="bankName">Bank Name:</label>
                      <input
                        type="text"
                        id="bankName"
                        name="bankName"
                        required
                      />
                    </div>
                    <div className="withdrocolumn">
                      <label htmlFor="bankAddress">Bank Address:</label>
                      <input
                        type="text"
                        id="bankAddress"
                        name="bankAddress"
                        required
                      />
                    </div>
                  </div>
                  <div className="withdrorow">
                    <div className="withdrocolumn">
                      <label htmlFor="bankCountry">Bank Country:</label>
                      <input
                        type="text"
                        id="bankCountry"
                        name="bankCountry"
                        required
                      />
                    </div>
                    <div className="withdrocolumn">
                      <label htmlFor="accountNumber">
                        Bank Account Number:
                      </label>
                      <input
                        type="text"
                        id="accountNumber"
                        name="accountNumber"
                        required
                      />
                    </div>
                  </div>
                  <div className="withdrorow">
                    <div className="withdrocolumn">
                      <label htmlFor="accountType">Account Type:</label>
                      <select id="accountType" name="accountType" required>
                        <option value="checking">Checking</option>
                        <option value="savings">Savings</option>
                      </select>
                    </div>
                    <div className="withdrocolumn">
                      <label htmlFor="swiftCode">SWIFT/BIC Code:</label>
                      <input
                        type="text"
                        id="swiftCode"
                        name="swiftCode"
                        required
                      />
                    </div>
                  </div>
                  <div className="withdrorow">
                    <div className="withdrocolumn">
                      <label htmlFor="iban">
                        IBAN (International Bank Account Number):
                      </label>
                      <input type="text" id="iban" name="iban" required />
                    </div>
                    <div className="withdrocolumn">
                      <label htmlFor="routingNumber">
                        Routing Number/Sort Code:
                      </label>
                      <input
                        type="text"
                        id="routingNumber"
                        name="routingNumber"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
 
              {payoutMethod === "westernUnion" && (
                <div id="westernUnionFields">
                  <div className="withdrorow">
                    <div className="withdrocolumn">
                      <label htmlFor="recipientName">Recipient Name:</label>
                      <input
                        type="text"
                        id="recipientName"
                        name="recipientName"
                        required
                      />
                    </div>
                    <div className="withdrocolumn">
                      <label htmlFor="recipientAddress">
                        Recipient Address:
                      </label>
                      <input
                        type="text"
                        id="recipientAddress"
                        name="recipientAddress"
                        required
                      />
                    </div>
                  </div>
                  <div className="withdrorow">
                    <div className="withdrocolumn">
                      <label htmlFor="country">Country:</label>
                      <input type="text" id="country" name="country" required />
                    </div>
                    <div className="withdrocolumn">
                      <label htmlFor="phone">Phone Number:</label>
                      <input type="text" id="phone" name="wuphone" required />
                    </div>
                  </div>
                </div>
              )}
              <div className="withdrorow">
                <div className="withdrocolumn">
                  <select
                    id="payoutOption"
                    name="payoutOption"
                    required
                    className="custom-selectop"
                  >
                    <option value="oneTime" className="custom-option">
                      One-time Payout: 24 hours after guest check-in
                    </option>
                    <option value="monthly" className="custom-option">
                      Monthly Payout: 24 hours after guest check-in, and every
                      25 days thereafter if it’s a monthly stay
                    </option>
                  </select>
                </div>
                <div className="withdrocolumn">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    // onChange={(e) => setWithdrawalAmount(e.target.value)}
                    onChange={handleWithdrawalAmountChange}
                    placeholder="Withdrawal Amount"
                    required
                  />
                  {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                  )}
                </div>
              </div>
              <div className="ff">
                <div className="withdrorowbtn">
                  <div className="withdrocolumnbtn">
                    <button
                      style={{
                        background:
                          "linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)",
                        color: "black",
                        border: "none",
                        fontSize: "12px",
                        color: "#fff",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="withdrocolumnbtn">
                    <button
                      style={{
                        background: "transparent",
                        color: "black",
                        border: "1px solid #000",
                        fontSize: "12px",
                      }}
                      type="button"
                      onClick={handleCloseUserPlusPopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
 
export default Sidebar;
 
 