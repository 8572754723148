
 
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {GoogleAuthProvider, getAuth} from "firebase/auth"
 
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEMCGfek7CT-rTVSK_wRCoUrdHRkmIy3Q",
  authDomain: "react-firebase--auth-e9f2b.firebaseapp.com",
  projectId: "react-firebase--auth-e9f2b",
  storageBucket: "react-firebase--auth-e9f2b.firebasestorage.app",
  messagingSenderId: "1020068066228",
  appId: "1:1020068066228:web:e3287b677ac06c68c2f8e1"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
 
export const auth = getAuth();
export const googleAuthProvider = new GoogleAuthProvider();
export default app;
 
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
 
// // Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyAn8IY4gyvzR5XCzhAxhcnH4uvmeq_HBx0",
//     authDomain: "caribbeaneaze.firebaseapp.com",
//     projectId: "caribbeaneaze",
//     storageBucket: "caribbeaneaze.appspot.com",
//     messagingSenderId: "212268879570",
//     appId: "1:212268879570:web:14aba5b81cded3c979e70b",
//     measurementId: "G-TNKG8FZ9HQ"
// };
 
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
 
// export { auth };
 
 
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { getAuth } from 'firebase/auth';
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
 
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD81ZbPIjDfCuujx9UYAmqB4hrOe4TOLCM",
//   authDomain: "test-637e5.firebaseapp.com",
//   projectId: "test-637e5",
//   storageBucket: "test-637e5.appspot.com",
//   messagingSenderId: "552998543778",
//   appId: "1:552998543778:web:5cf7f68c017aca706792fb",
//   measurementId: "G-DPLKRL47KK"
// };
 
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// export { auth };
 
 

 