import React, { useState, useEffect } from 'react';
import OTPInput from 'react-otp-input';
import './OtpInputeFiled.css';
import axios from 'axios';
import BaseUrl from '../Server/BaseUrl';
 
const URL = `${BaseUrl.BaseUrl}/api/send-otp`;
 
const PhoneAuth = ({ NextCallBack }) => {
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [message, setMessage] = useState('');
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const [timer, setTimer] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const user_id = localStorage.user_id;
 
  const OTP_EXPIRATION_TIME = 1 * 60 * 1000; // 1 minutes in milliseconds
 
  // Fetch registered email when the component mounts
  useEffect(() => {
    fetchUserData();
  }, []);
 
  useEffect(() => {
    const otpTime = localStorage.getItem('otpTime');
    if (otpTime) {
      const currentTime = Date.now();
      const timeDifference = currentTime - otpTime;
 
      if (timeDifference >= OTP_EXPIRATION_TIME) {
        setIsOtpExpired(true); // OTP has expired
      } else {
        setTimer(OTP_EXPIRATION_TIME - timeDifference); // OTP is still valid
        setOtpSent(true); // Show OTP input field
      }
    }
 
    let interval;
    if (!isOtpExpired && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1000) {
            clearInterval(interval);
            setIsOtpExpired(true);
            return 0;
          }
          return prev - 1000;
        });
      }, 1000);
    }
 
    return () => clearInterval(interval);
  }, [timer, isOtpExpired]);
 
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
 
  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/auth/user?user_id=${user_id}`
      );
      const data = await response.json();
    //   console.log('user data for user id get ', data);
      if (response.ok) {
        setEmail1(data.user.email); // Save registered email
      } else {
        setErrorMessage(data.message);
        alert(data.message);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setErrorMessage('Failed to fetch user data');
    }
  };
 
  const handleSendOtp = async (e) => {
    e.preventDefault();
 
    if (!email) {
      setMessage('Please enter a valid Email Address.');
      return;
    }
 
    if (email !== email1) {
      setMessage('Email does not match the registered email.');
      return;
    }
 
    try {
      const response = await axios.post(URL, { email });
      if (response.status === 201) {
        const currentTime = Date.now();
 
        localStorage.setItem('otp', response.data.user.otp);
        localStorage.setItem('otpTime', currentTime);
 
        setOtpSent(true);
        setMessage('OTP sent successfully!');
        setIsOtpExpired(false);
        setTimer(OTP_EXPIRATION_TIME);
      } else {
        setMessage('Error sending OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('Error sending OTP. Please try again.');
    }
  };
 
  const handleVerifyOtp = () => {
    const storedOtp = localStorage.getItem('otp');
 
    if (!otp) {
      setMessage('Please enter the OTP.');
      return;
    }
 
    if (otp === storedOtp) {
      setMessage('Your OTP matched! Please proceed to the next process.');
      localStorage.removeItem('otp');
      localStorage.removeItem('otpTime');
      NextCallBack({ navigationTo: 'Pasport_veri', id: 4, bt_type: 'Next' });
    } else {
      setMessage('OTP does not match. Please try again.');
    }
  };
 
  const handleResendOtp = () => {
    setIsOtpExpired(false);
    setTimer(OTP_EXPIRATION_TIME);
    handleSendOtp(new Event('click'));
  };
 
  return (
    <div
      className="v-phone-number"
      style={{
        width: '70%',
        paddingLeft: '20px',
      }}
    >
      <label style={{ marginLeft: 20 }}>Step 3/8</label>
      <h4 style={{ fontSize: 25, fontWeight: '700', marginLeft: 20 }}>
        Verify Email
      </h4>
      <div style={{ height: '40%', borderColor: '#E2E8F0' }}>
        <form onSubmit={handleSendOtp}>
          <label style={{ color: '#474554' }}>Enter Email</label>
          <br />
          <div className="mo-no" style={{ display: 'flex', width: '60%',gap:'10px' }}>
            <input
            id='emailinputwidth'
              type="email"
              placeholder="Enter Registered Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                height: 45,
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #ccc',
              }}
            />
            <button
              className="next-btn"
              id='verifybtnwidth'
              style={{
                color: '#000',
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 10,
                height: 'auto',
                marginLeft: 10,
                width:"30%",
                fontSize:'12px'
              }}
              type="submit"
              disabled={timer > 0}
            >
              {timer > 0 ? `Resend OTP in ${formatTime(timer)}` : 'Verify'}
            </button>
          </div>
        </form>
 
        {otpSent && !isOtpExpired && (
          <div className="otp-input">
            <p>Enter the OTP sent to your email:</p>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: 35,
                height: 35,
                fontSize: '18px',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                textAlign: 'center',
              }}
            />
            <button
              className="verify-btn"
              style={{
                color: '#000',
                background: '#fff',
                border: '1px solid #000',
                width: 'auto',
                borderRadius: 10,
                height: 40,
                marginTop: "10px",
                fontSize:12,
              }}
              onClick={handleVerifyOtp}
            >
              Verify OTP
            </button>
          </div>
        )}
 
        {isOtpExpired && (
          <div>
            <p>OTP has expired. Please resend the OTP.</p>
            <button
              className="resend-btn"
              style={{
                color: '#000',
                background: '#fff',
                border: '1px solid #000',
                width: 'auto',
                borderRadius: 10,
                height: '40px',
                marginTop: 10,
                fontSize:'12px',
              }}
              onClick={handleResendOtp}
            >
              Resend OTP
            </button>
          </div>
        )}
 
        {message && <p>{message}</p>}
      </div>
 
      <div style={{ marginTop: '60px' }} id="recaptcha"></div>
      <div
        className="main-btn"
        style={{
          display: 'flex',
          marginTop: '1%',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="back-btn"
          style={{
            width: '10%',
            borderRadius: 10,
            height: '45px',
            borderWidth: 0,
            background: 'white',
            color: 'black',
          }}
          onClick={() =>
            NextCallBack({
              navigationTo: 'Upload_Profile',
              id: 2,
              currentid: 3,
              bt_type: 'Back',
            })
          }
        >
          Back
        </button>
        <button
          className="next-btn"
          style={{
            color: 'white',
            background: '#F15A29',
            width: '15%',
            borderRadius: 10,
            height: '45px',
            marginLeft: 10,
            border: 'none',
          }}
          onClick={handleVerifyOtp}
        >
          Next
        </button>
      </div>
    </div>
  );
};
 
export default PhoneAuth;
 
 
 
 
 