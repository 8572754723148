import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaPen } from "react-icons/fa"; // Import the pencil icon
import BaseUrl from "../../Server/BaseUrl";
import "./UserProfile.css";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

const UserProfile = () => {
  const { userid } = useParams();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);

  useEffect(() => {
    setLoadingProfile(true);
    fetch(BaseUrl.BaseUrl + `/user_profile/${userid}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        return response.json();
      })
      .then((data) => {
        setUserData(data);
        setLoadingProfile(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoadingProfile(false);
      });
  }, [userid]);

  if (!userData) {
    return (
      <SkeletonWrapper
        containerClass="skeleton-user-profile"
        items={[
          { className: "skeleton-pp" }, // profile pic

          { className: "skeleton-dn" }, // display name

          { className: "skeleton-db" }, // display button
        ]}
      />
    );
  }

  if (loadingProfile) {
    return (
      <SkeletonWrapper
        containerClass="skeleton-user-profile"
        items={[
          { className: "skeleton-pp" }, // profile pic

          { className: "skeleton-dn" }, // display name

          { className: "skeleton-db" }, // display button
        ]}
      />
    );
  }

  const sanitizedProfileUrl = userData?.profile_url
  // const sanitizedProfileUrl = userData?.profile_url?.replace(
  //   /^http:\/\/localhost:4000/,
  //   ""
  // );
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadProfileImage(file); // Trigger upload after selecting the image
    }
  };

  // Function to upload the profile image to the server
  const uploadProfileImage = (file) => {
    const formData = new FormData();
    formData.append("profile_url", file); // Add the image file to the FormData
    formData.append("user_id", userid); // Send the user ID to associate with the image

    fetch(BaseUrl.BaseUrl + "/profile/upload", {
      method: "PATCH", // Use PATCH to update the profile image
      body: formData, // Send the form data with the image
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.profile_url) {
          // Update the user data to reflect the new image URL
          setUserData((prevData) => ({
            ...prevData,
            profile_url: data.profile_url, // Assume the server returns the new profile URL
          }));
          setSelectedImage(data.profile_url); // Update the local preview
          console.log("Profile Image Updated Successfully");
        } else {
          alert("Failed to upload the image.");
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image.");
      });
  };

  console.log("showing the user profile data =====>", userData);

  return (
    <div
      className="booking-box"
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <div
        className="box-profile"
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="user-pro"
          style={{
            display: "flex",
            width: "50%",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          {sanitizedProfileUrl && (
            <div style={{ position: "relative" }}>
              <img
                className="img-pro"
                style={{
                  width: 50,
                  height: 50,
                  cursor: "pointer",
                  borderRadius: "50%",
                }}
                src={
                  selectedImage || `${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`
                }
                alt="Profile"
                onClick={() => setShowModal(true)}
              />
            </div>
          )}
          <div
            className="pro-icon"
            style={{ marginLeft: 10, display: "flex", flexDirection: "column" }}
          >
            <label
              style={{ fontSize: 16, fontWeight: "700", color: "#0F172A" }}
            >
              {userData?.first_name} {userData?.last_name}
            </label>
          </div>
        </div>

        {/* Adjusted button size */}
        <button
          className="myprofile"
          id="myprofo"
          style={{
            height: 50,
            borderRadius: 10,
            fontSize: 14,
            fontWeight: "600",
            background: "#0F172A",
            color: "white",
            paddingLeft: 20,
            paddingRight: 20,
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
          onClick={() => setShowModal(true)}
        >
          My Profile
        </button>
      </div>
      {showModal && (
        <div className="modal-overlays">
          <div className="modal-containers">
            <div className="modal-headers">
              <h2>
                {userData?.first_name} {userData?.last_name}
              </h2>
              <button className="closebtns" onClick={() => setShowModal(false)}>
                X
              </button>
            </div>

            <div className="modal-contents">
              <img
                src={
                  selectedImage || `${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`
                }
                alt=""
                className="modal-images"
              />
              <input
                id="fileInput"
                type="file"
                className="hidden-inputs"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <FaPen
                onClick={() => document.getElementById("fileInput").click()}
                className="edit-icons"
              />
              <p>
                <strong>Location:</strong> {userData?.country || "N/A"}
              </p>
              <p>
                <strong>Speaks:</strong> English
              </p>
              <p>
                <strong>Email:</strong> {userData?.email || "N/A"}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
