import React, { Component } from 'react';
import './Image.css';
import BaseUrl from '../../Server/BaseUrl';
import SkeletonWrapper from '../Skeleton/SkeletonWrapper';
SkeletonWrapper;

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  handlePrevClick = () => {
    this.setState((prevState) => ({
      currentIndex:
        prevState.currentIndex === 0
          ? this.props.propertyData.property_images.length - 1
          : prevState.currentIndex - 1,
    }));
  };

  handleNextClick = () => {
    this.setState((prevState) => ({
      currentIndex:
        (prevState.currentIndex + 1) % this.props.propertyData.property_images.length,
    }));
  };

  render() {
    const { propertyData } = this.props;
    const { currentIndex } = this.state;
    const { loading } = this.props;
    const { error } = this.props;

    if (loading) {
      return (
        <div style={{ width: "100%", position: "relative" }}>
          <SkeletonWrapper
            containerClass="Skeleton-image-container"
            items={[
              { className: "skeleton-images" },
              { className: "skeleton-images" },
              { className: "skeleton-images" },
            ]}
          />
        </div>
      );
    }

    if (
      error ||
      !propertyData ||
      !Array.isArray(propertyData.property_images) ||
      propertyData.property_images.length === 0
    ) {
      return (
        <div style={{ width: "100%", position: "relative" }}>
          <div className="property-slide-error">
            No property images availabel.....
          </div>
        </div>
      );
    }

    const totalImages = propertyData.property_images.length;

    // Calculate the indices for the next 2 images
    const indicesToShow = [
      currentIndex,
      (currentIndex + 1) % totalImages,
      (currentIndex + 2) % totalImages,
    ];

    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <div className="property-slide">
          {indicesToShow.map((index) => (
            <img
              key={index}
              className="property-image"
              src={`${BaseUrl.BaseUrl}/Images/${propertyData.property_images[index].filename}`}
              alt={`Image ${index}`}
            />
          ))}
        </div>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '20px',
            transform: 'translateY(-50%)',
            borderRadius: '30px',
            zIndex: 2,
          }}
        >
          <img
            src={require('../../../assets/Untitled_designtrasprent2.png')}
            style={{
              background: 'none',
              height: 50,
              width: 50,
              borderRadius: "none",
              cursor: 'pointer',
            }}
            onClick={this.handlePrevClick}
            alt="Previous"
          />
        </div>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '20px',
            transform: 'translateY(-50%)',
            borderRadius: '30px',
            zIndex: 2,
          }}
        >
          <img
            src={require('../../../assets/Untitled_designtrasprent.png')}
            style={{
              background: 'none',
              height: 50,
              width: 50,
              borderRadius: 30,
              cursor: 'pointer',
            }}
            onClick={this.handleNextClick}
            alt="Next"
          />
        </div>
      </div>
    );
  }
}
