import React, { useEffect } from "react";
import "./chat-header.css";
import BaseUrl from "../../Server/BaseUrl";

const ChatHeader = ({ user }) => {
  // Log the chats array to the console whenever it updates
  useEffect(() => {
    console.log("user Array:", user);
  }, [user]);
  const sanitizedProfileUrl = user.profile_url
  return (
    <div className="custom-header-container1">
      <div className="custom-user-container1">
        <img src={`${BaseUrl.BaseUrl}/${sanitizedProfileUrl}`} alt="" className="custom-avatar" />
        <div>
          <h3 className="custom-user-name">
            {" "}
            {user.firstName} {user.lastName}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
