import React, { useState, useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css";
import "./JobsDetailes.css";
import BaseUrl from "../../Server/BaseUrl";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";

const JobsDetailes = ({ userData }) => {
  const [selectedDates, setSelectedDates] = useState([]); // Newly selected dates
  const [updatedAvailabilityDates, setUpdatedAvailabilityDates] = useState([]); // Dates from the API
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(true); // Simulate loading state

  const showMessageWithTimeout = (message) => {
    setStatusMessage(message);
    setTimeout(() => setStatusMessage(""), 2000);
  };

  const propertyId = userData?.property_id;

  // Fetch availability dates when the component mounts or propertyId changes
  useEffect(() => {
    setLoading(true);
    if (propertyId) {
      fetch(`${BaseUrl.BaseUrl}/property_availabilities/${propertyId}`)
        .then((response) => response.json())
        .then((data) => {
          const unavailableDates = (
            data?.property?.availabilityDates || []
          ).map((entry) => {
            const utcDate = new Date(entry.date);
            return new Date(
              utcDate.getUTCFullYear(),
              utcDate.getUTCMonth(),
              utcDate.getUTCDate()
            );
          });
          setUpdatedAvailabilityDates(unavailableDates);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching property data:", error);
          setLoading(false);
        });
    }
  }, [propertyId]);

  const updateAvailabilityDates = async () => {
    const user_id = localStorage.user_id;

    try {
      if (!selectedDates.length && !updatedAvailabilityDates.length) {
        alert("Please select at least one date.");
        return;
      }

      const allDates = [...updatedAvailabilityDates, ...selectedDates];
      const uniqueDates = Array.from(
        new Set(
          allDates.map((date) => {
            const jsDate = date instanceof Date ? date : new Date(date);
            return jsDate.toISOString().split("T")[0];
          })
        )
      ).map((dateString) => new Date(dateString));

      const formattedDates = uniqueDates.map(
        (date) => date.toISOString().split("T")[0]
      );

      const response = await fetch(`${BaseUrl.BaseUrl}/property/availability`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          property_id: propertyId,
          availabilityDates: formattedDates,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        const updatedDates = data?.property?.availabilityDates.map((entry) => {
          const utcDate = new Date(entry.date);
          return new Date(
            utcDate.getUTCFullYear(),
            utcDate.getUTCMonth(),
            utcDate.getUTCDate()
          );
        });
        setUpdatedAvailabilityDates(updatedDates);
        setSelectedDates([]); // Clear newly selected dates
        showMessageWithTimeout("Availability dates updated successfully");
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Error updating availability dates:", error);
      alert("An error occurred while updating availability dates");
    }
  };

  const handleDateSelect = (date) => {
    const normalizedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    const isAlreadyUnavailable = updatedAvailabilityDates.some(
      (d) => d instanceof Date && d.getTime() === normalizedDate.getTime()
    );

    if (isAlreadyUnavailable) {
      // If it's in updatedAvailabilityDates, remove it from there
      const updatedDates = updatedAvailabilityDates.filter(
        (d) => d.getTime() !== normalizedDate.getTime()
      );
      setUpdatedAvailabilityDates(updatedDates);
    } else {
      // Otherwise, toggle it in selectedDates
      const isAlreadySelected = selectedDates.some(
        (d) => d instanceof Date && d.getTime() === normalizedDate.getTime()
      );

      if (isAlreadySelected) {
        setSelectedDates((prevDates) =>
          prevDates.filter((d) => d.getTime() !== normalizedDate.getTime())
        );
      } else {
        setSelectedDates((prevDates) => [...prevDates, normalizedDate]);
      }
    }
  };

  if (loading) {
    return (
      <SkeletonWrapper
        containerClass="skeleton-job-details"
        items={[
          { className: "skeleton-header" },

          { className: "skeleton-balance1" },

          { className: "skeleton-balance-item" },

          { className: "skeleton-balance" },

          { className: "skeleton-balance-item" },

          { className: "skeleton-button1" },

          { className: "skeleton-button" },
        ]}
      />
    );
  }

  return (
    <div style={{ marginTop: 20, paddingLeft: "20px" }}>
      <div>
        <label style={{ fontSize: 21, fontWeight: "600", marginLeft: 25 }}>
          Status
        </label>
        <ul
          style={{
            listStyleType: "none",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <div className="balance_1">
            <li
              style={{
                fontSize: 20,
                fontWeight: "500",
                color: "#0F172A",
                marginTop: 10,
              }}
            >
              Balance
            </li>
            <div style={{ display: "flex" }}>
              <li
                style={{
                  fontSize: 28,
                  fontWeight: "600",
                  color: "#047857",
                }}
              >
                ${userData?.completed_job_amount}
              </li>
            </div>
          </div>
        </ul>
        <ul
          style={{
            listStyleType: "none",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <div className="balance_1">
            <p> Active Jobs</p>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  fontSize: 25,
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                }}
              >
                {userData?.activeJob} jobs{" "}
              </label>
              <li
                style={{
                  fontSize: 20,
                  fontWeight: "500",
                  color: "#047857",
                }}
              >
                {" "}
                (${userData?.active_job_amount})
              </li>
            </div>
          </div>
        </ul>
        {/* <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div className='balance_1'>
                          <p>  Earned this Month </p>
                        <div style={{ display: 'flex' }}>
                            <li style={{
                                fontSize: 22,
                                fontWeight: '500',
                                color: '#047857'
                            }}> (${userData?.current_month_earning})
                            </li>
                        </div>
                    </div>
                </ul> */}
        {/* <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div className='balance_1'>
                          <p>  Expected Earning</p>
                        <div style={{ display: 'flex' }}>
                            <li style={{
                                fontSize: 22,
                                fontWeight: '500',
                                color: '#047857'
                            }}> (${userData?.
                                expected_earnings})
                            </li>
                        </div>
                    </div>
                </ul> */}
      </div>
      <div className="my-calendar" style={{ width: "99%", paddingRight: 10 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label
            style={{ fontSize: 19, fontWeight: "500", wordBreak: "break-all" }}
          >
            My Availability Calendar
          </label>
        </div>
        {statusMessage && (
          <p style={{ color: "#22C55E", textAlign: "left" }}>{statusMessage}</p>
        )}
        <div style={{ marginTop: 10, width: "30%" }}>
          <Calendar
            multiple
            value={selectedDates}
            onChange={setSelectedDates}
            mapDays={({ date }) => {
              const jsDate = date instanceof Date ? date : date.toDate?.();
              const normalizedDate = new Date(
                jsDate.getFullYear(),
                jsDate.getMonth(),
                jsDate.getDate()
              );

              const isUnavailable = updatedAvailabilityDates.some(
                (d) =>
                  d instanceof Date && d.getTime() === normalizedDate.getTime()
              );

              const isCurrentlySelected = selectedDates.some(
                (d) =>
                  d instanceof Date && d.getTime() === normalizedDate.getTime()
              );

              return {
                className: isUnavailable
                  ? "red"
                  : isCurrentlySelected
                  ? "selected"
                  : "",
                style: isUnavailable
                  ? { backgroundColor: "#ffcccc", color: "red" }
                  : isCurrentlySelected
                  ? { backgroundColor: "#cce5ff", color: "#000" }
                  : undefined,
              };
            }}
            onDayClick={handleDateSelect}
          />
        </div>
        <button
          onClick={updateAvailabilityDates}
          style={{
            marginTop: 20,
            width: "80%",
            marginLeft: "20px",
            padding: 0,
            border: "none",
          }}
          disabled={
            selectedDates.length === 0 && updatedAvailabilityDates.length === 0
          }
        >
          Update Availability
        </button>
      </div>
    </div>
  );
};

export default JobsDetailes;
