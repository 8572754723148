import React from "react";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { Routes, Route } from "react-router-dom";
import Signup from "./screens/Signup";
import Login from "./screens/Login";
import TermsCondition from "./TermsCondition.js";
import RegistrationProcess from "./screens/RegistrationProcess";
import Deshbord from "./screens/Components/Deshbord/Deshboard";
import MyListing from "./screens/MyListing";
import BookingHistry from "./screens/BookingHistry";
import BookingUserHistory from "./screens/BookingUserHistory.js";
import EarningScreen from "./screens/EarningScreen";
import Home from "./screens/HomeComonent/Home";
import PropertyPage from "./screens/HomeComonent/property/PropertyPage";
import ClientProfile from "./screens/Components/ClientProfile";
import Checkout from "./screens/HomeComonent/Checkout";
import MyBooking from "./screens/HomeComonent/PropertyOwnerProfile/MyBooking";
import EmailSupport from "./screens/HomeComonent/EmailSupport";
import MainMessage from "./screens/HomeComonent/MessageChat/MainMessage";
import AddProperty from "./screens/Components/AddNewProperty/AddProperty";
import VarifyBooking from "./screens/HomeComonent/PropertyOwnerProfile/VarifyBooking";
import Diversifysidebar from "./screens/Components/Deshbord/Diversify property/Diversifysidebar.js";
import PandingApprovalProperty from "../src/screens/Components/Deshbord/PendingApprovalProperty";
import UserSignup from "./screens/UserSignup";
import UserLogin from "./screens/UserLogin";
import TermConditionUser from "./TermConditionUser";
import Footer from "./screens/HomeComonent/Footer/Footer.js";

import TermConditions from "./screens/HomeComonent/Footer/Termconditions.js";
import PrivacyPolicy from "./screens/HomeComonent/Footer/Privacypolicy.js";
import RefundPolicy from "./screens/HomeComonent/Footer/Refundpolicy.js";
import CookiePolicy from "./screens/HomeComonent/Footer/Cookiepolicy.js";
import CancellationPolicy from "./screens/HomeComonent/Footer/Cancellationpolicy.js";
import Aboutus from "./screens/HomeComonent/Footer/Aboutus.js";
import EditCompoet from "./screens/HomeComonent/property/EditCompoet.js";

import EditForm from "./screens/HomeComonent/property/EditForm.js";

import Located from "./screens/Components/AddNewProperty/PropertyLoacation.js";

import PropertyImages from "./screens/Components/AddNewProperty/Propertyimage.js";

import AddExtraService from "./screens/Components/AddNewProperty/AddExtraService.js";

import ReviewForm from "./screens/HomeComonent/property/ReviewForm.js";
import ScrollToTop from "./screens/ScrollToTop.js";
import { RatingProvider } from "../src/RatingContext.js";
import ProtectedRoute from "./ProtectedRoute.js";
import UserMainChats from "./screens/HomeComonent/MessageChat/UserMainChats.js";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <RatingProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/Login" element={<Login />} style={{ width: "100vw" }} />
          <Route
            path="/Signup"
            element={<Signup />}
            style={{ width: "100vw" }}
          />
          <Route path="/userlogin" element={<UserLogin />} />
          <Route path="/usersignup" element={<UserSignup />} />
          <Route path="/" element={<Home />} style={{ width: "100vw" }} />

          {/* Protected Routes */}
          {/* <Route path='/' element={<RegistrationHeader/> }/> */}
          <Route
            path="/earning/"
            element={
              <ProtectedRoute>
                {" "}
                <EarningScreen />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />

          <Route
            path="/Located/"
            element={
              <ProtectedRoute>
                {" "}
                <Located />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/PropertyImages/"
            element={
              <ProtectedRoute>
                {" "}
                <PropertyImages />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/AddExtraService/"
            element={
              <ProtectedRoute>
                <AddExtraService />
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />

          {/* <Route path="/booking/:userid" component={BookingHistory} /> */}

          <Route
            path="/booking/:userid"
            element={
              <ProtectedRoute>
                <BookingHistry />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/booking_user/:userid"
            element={
              <ProtectedRoute>
                <BookingUserHistory />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/mylisting/:userid"
            element={
              <ProtectedRoute>
                <MyListing />
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/Dashboard/:userid"
            element={
              <ProtectedRoute>
                <Deshbord />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/Registration"
            element={
              <ProtectedRoute>
                <RegistrationProcess />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/EditCompoet/:propertyId"
            element={
              <ProtectedRoute>
                <EditCompoet />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/EditForm/:propertyId"
            element={
              <ProtectedRoute>
                <EditForm />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />

          <Route
            path="/TermsCondition"
            element={
              <ProtectedRoute>
                <TermsCondition />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />

          <Route
            path="/TermsConditionuser"
            element={
              <ProtectedRoute>
                <TermConditionUser />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />

          <Route
            path="/PropertyPage/:propertyId"
            element={<PropertyPage />}
            style={{ width: "100vw" }}
          />

          <Route
            path="/Checkout"
            element={
              <ProtectedRoute>
                <Checkout />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/MyBooking"
            element={
              <ProtectedRoute>
                <MyBooking />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/EmailSupport"
            element={<EmailSupport />}
            style={{ width: "100vw" }}
          />
          <Route
            path="/Message"
            element={
              <ProtectedRoute>
                <MainMessage />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/Chats"
            element={
              <ProtectedRoute>
                <UserMainChats />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/AddProperty"
            element={
              <ProtectedRoute>
                <AddProperty />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/divercify"
            element={
              <ProtectedRoute>
                <Diversifysidebar />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />

          <Route
            path="/VarifyBooking"
            element={
              <ProtectedRoute>
                <VarifyBooking />{" "}
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          <Route
            path="/PandingApproval/:userid"
            element={
              <ProtectedRoute>
                <PandingApprovalProperty />
              </ProtectedRoute>
            }
            style={{ width: "100vw" }}
          />
          {/*<Route path='/PandingApproval/:userid' element={<TermsCondition />} style={{width: '100vw'}}/>*/}
          {/* <Route path='/Propertylist' element={<Propertylist />} style={{width: '100vw'}}/> */}
          <Route
            path="/earning/:userid"
            element={
              <ProtectedRoute>
                <EarningScreen />{" "}
              </ProtectedRoute>
            }
          />

          <Route
            path="/aboutus"
            element={<Aboutus />}
            style={{ width: "100vw" }}
          />
          <Route
            path="/TermConditions"
            element={<TermConditions />}
            style={{ width: "100vw" }}
          />
          <Route
            path="/PrivacyPolicy"
            element={<PrivacyPolicy />}
            style={{ width: "100vw" }}
          />
          <Route
            path="/CancelaionPolicy"
            element={<CancellationPolicy />}
            style={{ width: "100vw" }}
          />
          <Route
            path="/Refundpolicy"
            element={<RefundPolicy />}
            style={{ width: "100vw" }}
          />
          <Route
            path="/cookiespolicy"
            element={<CookiePolicy />}
            style={{ width: "100vw" }}
          />
          <Route path="*" />
        </Routes>
      </RatingProvider>
    </>
  );
};

export default App;
