import React, { Component } from "react";
import Popup from "reactjs-popup";
import BaseUrl from "../../Server/BaseUrl";
import SkeletonWrapper from "../../HomeComonent/Skeleton/SkeletonWrapper";
import './Booking.css';
const Data = [
  {
    img: require("../../../assets/Ellipse 45.png"),
    title: "5xNight+ Raffting",
    desc: "Service by:Paul",
    price: "$40",
  },
  {
    img: require("../../../assets/Ellipse45(1).png"),
    title: "5xNight+ Raffting+Dinner",
    desc: "Service by:Paul",
    price: "$50",
  },
  {
    img: require("../../../assets/Ellipse 45 (2).png"),
    title: "5xNight+ Raffting",
    desc: "Service by:Paul",
    price: "$200",
  },
  {
    img: require("../../../assets/Ellipse 45 (3).png"),
    title: "5xNight+ Raffting",
    desc: "Service by:Paul",
    price: "$300",
  },
];

export default class Booking extends Component {
  render() {
    const { userData, loading, error } = this.props;
    console.log("user.....", userData?.data);

    const formatExtraServices = (services) => {
      try {
        const parsedServices = JSON.parse(services);
        return parsedServices.map((service) => service.item).join(", ");
      } catch (error) {
        console.error("Error parsing extra services:", error);
        return "";
      }
    };

    if (loading) {

      return (
        <SkeletonWrapper
          containerClass="skeleton-booking-details"
          items={[
            { className: "skeleton-header" },
            { className : "skeleton-bookings1"},
            { className : "skeleton-bookings2"},
            { className : "skeleton-bookings3"},
            { className : "skeleton-bookings4"},
            { className : "skeleton-bookings5"},
          ]}
        />
      );
    }

    if (error) {
      return <div>Error.....</div>;
    }

    return (
      <div>
        <div className="topMain" style={{ width: "100%" }}>
          <div className="epmt" style={{ height: 77 }}>
            <div
              style={{
                width: "100%",
                padding: 10,
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {" "}
              Booking
            </div>
            {/* <img src={require('../../../assets/mobile-bargar.png')} alt=""/> */}
          </div>
          {userData?.data?.length === 0 ? (
            <p
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
              }}
            >
              No bookings available
            </p>
          ) : (
            <div className="table-Page">
              <table className="table-full">
                <tbody>
                  {userData?.data?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: "0px 40px 0px 10px ",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.nights} Night
                        {item.extra_services &&
                          ", " + formatExtraServices(item.extra_services)}
                        <p>
                          <span style={{ color: "#64748B" }}>
                            Booking by: {item.user_name}
                          </span>
                        </p>
                      </td>

                      <td style={{ padding: "0px 40px 0px 40px " }}>
                        <div
                          style={{ whiteSpace: "nowrap", fontWeight: "400" }}
                        >
                          {" "}
                          {item.entry_date} to {item.exit_date}{" "}
                        </div>
                      </td>
                      <td
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          padding: "0px 20px 0px 20px ",
                          color: "#22C55E",
                        }}
                      >
                        ${item.owner_earnings}
                      </td>
                      <td
                        style={{
                          padding: "0px 20px 0px 20px",
                          whiteSpace: "nowrap",
                          color:
                            item.status.toLowerCase() === "completed"
                              ? "#22C55E"
                              : "#EF4444",
                        }}
                      >
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}
